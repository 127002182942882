import React, { Component, useEffect, useState } from 'react';

interface CardProps {
    name: string,
    level?: number | null | undefined,
    dateAcquired?: string,
    username: string
}

const SingleCard = (props: CardProps) => {
    const [backgroundImage, setBackgroundImage] = useState("");
    const [managerImage, setManagerImage] = useState("");


    useEffect(() => {
    }, []);

    return (
        <>
            <div className="singlecard-maincontainer">
                <div className="individual-card-background" style={{ backgroundImage: "url(" + backgroundImage + ")" }} >
                    <div className="individual-card-mainimage-container">
                        <img className="individual-card-manager-image" style={{ content: "url(" + managerImage + ")" }} />
                        <div className="individual-card-levelnumber-container">
                            <span className="individual-card-levelnumber-text"> {props.level} </span>
                        </div>
                    </div>
                </div>
                <div className="single-card-info-main-container">
                    <div className="card-name-text">
                        {props.name}
                    </div>
                    <div className="card-date-container">
                        ACQUIRED {props.dateAcquired}
                    </div>
                    <div className="card-info-container">
                        <div className="rowinfo-container">
                            <span className="card-entry-header-text">OWNER</span>
                            <span className="card-entry-data-text">{props.username}</span>
                        </div>

                        <div className="rowinfo-container">
                            <span className="card-entry-header-text">CURRENT PRICE</span>
                            <span className="card-entry-data-text">$1.00</span>
                        </div>

                        <div className="rowinfo-container">
                            <span className="card-entry-header-text">CONTRACT ADDRESS</span>
                            <span className="card-entry-data-text">0x495f947276749ce646f68ac8c248420045cb7b5e</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleCard;