export enum RoutesPaths {
  Login = "/login",
  Register = "/register",
  Hedera = "/hedera",
  ForgotPassword = "/forgotpassword",
  ForgotPasswordReset = "/forgotpassword/reset",
  Dashboard = "/dashboard",
  ViewIndividual = "/viewindividual",
  MarkerCreator = "/markercreator",
  RarityCreator = "/raritycreator",
  RelicRewards = "/relicrewards",
  Admin = "/admin",
  Default = "/"
}