import React, { useEffect, useState } from "react";

//Sources
import Whopper from "../media/whopper.png";

//Button Image
//import ArrowButtonImage from "../media/arrow-button.png";

interface RedeemableProps {
  sourceName: string;
  redeemableName: string;
  redeemableInfo?: string;
}

async function setSpecificRedeemableUI(name: string) {
  var result = "";

  //Going off of XD, these may need to be adjusted
  switch (name) {
    case "Whopper":
      //onsole.log("HIT")
      result = Whopper;
      break;
    default:
      break;
  }

  return result;
}

const Redeemable = (props: RedeemableProps) => {
  const [redeemableImage, setRedeemableImage] = useState("");

  useEffect(() => {
    setSpecificRedeemableUI(props.redeemableName).then((result) => {
      setRedeemableImage(result);
    });
  }, []);

  return (
    <>
      <div className="redeemable-entry-container">
        <div
          className="redeemeable-image"
          style={{ content: "url(" + redeemableImage + ")" }}
        ></div>
        <div className="redeemeable-info-container">
          <span className="source-name-text">{props.sourceName}</span>
          <span className="redeemable-name-text">{props.redeemableName}</span>
          <span className="redeemable-info-text">{props.redeemableInfo}</span>
        </div>
        <div className="redeem-button-container">
          <div className="redeem-button">
            <span>REDEEM</span>
          </div>
          <div className="arrow-button"></div>
        </div>
      </div>
    </>
  );
};

export default Redeemable;
