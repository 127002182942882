import axios from 'axios'
import {
  Client,
  AccountId,
  AccountCreateTransaction,
  AccountBalanceQuery,
  AccountInfoQuery,
  Hbar,
  Mnemonic,
  PublicKey,
  PrivateKey,
} from '@hashgraph/sdk'

import { HEDERA_TEST_KEYS } from '../hederakeys'

const axiosInst = axios.create({
  baseURL: 'http://vaultservices.wazuzu.com',
  headers: {
    'Content-Type': 'application/json',
  },
})

const treasuryAccountId = AccountId.fromString(HEDERA_TEST_KEYS.MY_ACCOUNT_ID!)
const treasuryPrivateKey = PrivateKey.fromString(
  HEDERA_TEST_KEYS.MY_PRIVATE_KEY!,
)
const treasuryPublicKey = PublicKey.fromString(HEDERA_TEST_KEYS.MY_PUBLIC_KEY!)
//const web3Key = process.env.WEB3_KEY

const TestNetNodeUrl = 'https://testnet.mirrornode.hedera.com/'

export class CreateHederaAccount {
  encryptedPrivateKey: string | undefined
  privateKey: string | undefined
  publicKey: string | undefined
  accountId: string | undefined
  seedPhrase: string[] | undefined
}

export class Account {
  encryptedPrivateKey: string | undefined
  publicKey: string | undefined
  accountId: string | undefined
}

export const createAccount = async () => {
  if (treasuryAccountId == null || treasuryPrivateKey == null) {
    return 'Environment variables treasuryAccountId and treasuryPrivateKey must be present'
  }
  try {
    const client = Client.forTestnet()
    client.setOperator(treasuryAccountId, treasuryPrivateKey)

    const mnemonic = await Mnemonic.generate12()
    //console.log(mnemonic)

    const newAccountPrivateKey = await mnemonic.toEd25519PrivateKey()
    const newAccountPublicKey = newAccountPrivateKey.publicKey

    const newAccount = await new AccountCreateTransaction()
      .setKey(newAccountPrivateKey)
      .setInitialBalance(Hbar.fromTinybars(50))
      .execute(client)

    const getReceipt = await newAccount.getReceipt(client)
    const newAccountId = getReceipt.accountId?.toString()

    //console.log(newAccountPrivateKey.toString())
    let encryptedKey = (
      await axiosInst.post('/api/crypto/encrypt', {
        privateKey: newAccountPrivateKey.toString(),
      })
    ).data
    const newAccountInfo = new CreateHederaAccount()
    newAccountInfo.encryptedPrivateKey = encryptedKey
    newAccountInfo.publicKey = newAccountPublicKey.toString()
    newAccountInfo.accountId = newAccountId
    newAccountInfo.privateKey = newAccountPrivateKey.toString()
    //newAccountInfo.jwt = CreateJWT(newAccountId);

    const wordArray = mnemonic.toString().split(' ')
    newAccountInfo.seedPhrase = wordArray

    client.close()
    return newAccountInfo
  } catch (error) {
    return (error as Error).toString()
  }
}

export const recover = async (seedPhraseInput: string) => {
  if (treasuryAccountId == null || treasuryPrivateKey == null) {
    return 'Environment variables treasuryAccountId and treasuryPrivateKey must be present'
  }
  try {
    const seedPhrase = seedPhraseInput
    const finalSeedPhrase = seedPhrase.replace(/["]+/g, '')
    const recoveredMnemonic = await Mnemonic.fromString(finalSeedPhrase)
    const recoveredPrivateKey = await recoveredMnemonic.toEd25519PrivateKey()
    const recoveredPublicKey = recoveredPrivateKey.publicKey.toString()

    const AccountList = await getAccountFromMirrorNode(recoveredPublicKey)
    const RecoveredAccList = []
    let encryptedKey = (
      await axiosInst.post('/api/crypto/encrypt', {
        privateKey: recoveredPrivateKey.toString(),
      })
    ).data
    for (const acc of AccountList.accounts) {
      const rAcc = new Account()
      rAcc.publicKey = recoveredPublicKey
      rAcc.encryptedPrivateKey = encryptedKey
      rAcc.accountId = acc.account
      RecoveredAccList.push(rAcc)
    }

    return RecoveredAccList
  } catch (error) {
    return (error as Error).toString()
  }
}

async function getAccountFromMirrorNode(publicKey: string) {
  var url = TestNetNodeUrl + 'api/v1/accounts?account.publickey=' + publicKey
  const response = await fetch(url, {
    method: 'GET',
  })
  return response.json()
}
