import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PlayerInventory from "../playerinventory.component";
import TowerTycoonsIcon from "../media/towertycoons-icon.svg";

const Footer = () => {

    useEffect(() => {
    }, []);

    return (
        <>
            <div className="footer-main-container">
                <div className='footer-content-container'>
                    <div className='footer-column'>
                        <span className='footer-column-header'>Resources</span>
                        <div className='footer-divider'></div>
                        <span className='footer-column-text'>Terms of Services</span>
                        <span className='footer-column-text'>Privacy Policy</span>
                        <span className='footer-column-text'>NFT Education</span>
                        <span className='footer-column-text'>Advice</span>
                    </div>
                    <div className='footer-column'>
                        <span className='footer-column-header'>Company</span>
                        <div className='footer-divider'></div>
                        <span className='footer-column-text'>About Us</span>
                        <span className='footer-column-text'>For Sponsors</span>
                        <span className='footer-column-text'>For Users</span>
                    </div>
                    <div className='footer-column'>
                        <span className='footer-column-header'>Reach Us</span>
                        <div className='footer-divider'></div>
                        <span className='footer-column-text'>info.dungeon@wazuzu.com</span>
                    </div>
                </div>
                <div className='footer-logo-container'>
                    <img className='footer-logo' />
                    <span className='footer-rightsreserved-text'>© 2021 Wazuzu Dungeon, All Rights Reserved</span>
                </div>
            </div>
        </>
    );
}

export default Footer;