/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext,
  useRef,
  useEffect,
  Dispatch,
  useCallback,
  PropsWithChildren,
} from "react";

import { IUserState } from "./usercontext.type";

import {
  getStoredUser,
  loginFromStorage,
  relog,
  userLogin,
  userLogout,
} from "./user.functions";
import { IAction } from "../common/storebase.types";
import { useEnhancedReducer } from "../common/enhancereducer.hook";
import { logger } from "../common/logger";
import { useApi } from "../api/api.context";

export enum UserActions {
  login = "login",
  loginSession = "loginSession",
  logout = "logout",
  loginError = "loginError",
  loading = "loading",
  loginFromStorage = "loginFromStorage",
  user = "user",
  token = "token",
  updateUser = "updateUser",
  error = "error",
  relog = "relog",
  adminScreen = "adminScreen",
}

const initialState = {
  token: null,
  user: null,
  loginError: false,
  loading: false,
  role: "",
  error: "",
};

const reducer = (state: IUserState, action: IAction): IUserState => {
  const newState = { ...state };
  switch (action.type) {
    case UserActions.error:
      newState.error = action.payload;
      break;
    case UserActions.loginError:
    case UserActions.loading:
      newState[action.type] = action.payload;
      break;
    case UserActions.token:
      newState.error = "";
      newState[action.type] = action.payload;
      break;
    case UserActions.user:
      newState.error = "";
      newState.user = action.payload;
      break;
    case UserActions.updateUser:
      newState.error = "";
      newState.user = { ...newState.user, ...action.payload };
      break;
    default:
      return state;
  }
  return newState;
};

export const UserStateContext: any = React.createContext({});
export const UserDispatchContext: any = React.createContext({});
export const UserStateRefContext: any = React.createContext({});

export const useUserState = (): IUserState => useContext(UserStateContext);
export const useUserDispatch = (): Dispatch<IAction> =>
  useContext(UserDispatchContext);
export const useUserStateRef = (): React.MutableRefObject<IUserState> =>
  useContext(UserStateRefContext);

const middleware = [
  logger("User"),
  userLogin,
  userLogout,
  loginFromStorage,
  relog,
];

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch, userStateRef] = useEnhancedReducer(
    reducer,
    initialState,
    (state) => ({ ...state }),
    middleware
  );

  useEffect(() => {
    dispatch({ type: UserActions.loginFromStorage });
  }, [dispatch]);

  // This is standard practice to get the user when the provider gets mounted
  // const getFullUser = async () => {
  //   if (state?.user?._id) {
  //     const resp = await getUser(state?.user?._id);
  //     if (resp?.Success) {
  //       console.log("resp getuser: ", resp?.data);
  //       dispatch({ type: UserActions.user, payload: resp.data });
  //     }
  //   }
  // };

  // This allows the user provider to log in from storage if its the first login
  const loginAttempt = useRef(false);
  if (!loginAttempt.current && !state.user) {
    dispatch({ type: UserActions.loginFromStorage });
    //getFullUser();
    loginAttempt.current = true;
  }

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        <UserStateRefContext.Provider value={userStateRef}>
          {children}
        </UserStateRefContext.Provider>
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};
