import { useEffect } from "react";
// import NFTTimelineEntry from "./nfttimelineentry.component";

interface INFTHistory {}

const NFTHistory = (props: INFTHistory) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="nft-timeline-body">
        <div className="nft-timeline-header-container">
          <span className="nft-timeline-header">Timeline</span>
          {/* <span className='nft-timeline-engagement-text ml-auto'>Engagement: 14.2%</span>
                    <div className='nft-timeline-trending-container'>
                        <span className='nft-timeline-trending-text'>Trending</span>
                    </div> */}
        </div>
        <div className="nft-timeline-entry-panel">
          <div className="nft-timeline-scrollbody">
            {/* Where Entries will go after retrieved */}
            {/* <NFTTimelineEntry brand="Citi Bike" />
            <NFTTimelineEntry brand="Citi Bike" />
            <NFTTimelineEntry brand="Fresh Pet" />
            <NFTTimelineEntry brand="Fresh Pet" />
            <NFTTimelineEntry brand="Fresh Pet" /> */}
            {/* <span className="text-white text-center">Coming Soon!</span> */}
            <div className="history-comingsoon">
              <span>Coming Soon!</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTHistory;
