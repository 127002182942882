import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Request } from "../../tools/api-request";
import { getTopDrops } from "../../actions/topdrops.action";
import CountdownTimer from "../countdowntimer/countdowntimer.component";
import TowerTycoonsIcon from "../../media/towertycoons-icon.svg";
import ProEraIcon from "../../media/proera-icon.svg";
import BeatSaberIcon from "../../media/beatsaber-icon.svg";

interface ITopDrops {
  setPanelOpen: Function;
}

const TopDrops = (props: ITopDrops) => {
  const [drops, setDrops] = useState<any>(null);
  var dropsDataArray: any[] = [];

  async function RefreshTopDrops() {
    var dropsData = await getTopDrops().call(null);
    for (let dropIndex = 0; dropIndex < dropsData.length; dropIndex++) {
      dropsDataArray.push(
        <>
          <td className="topdrops-element">
            <span className="topdrops-header">
              {dropsData[dropIndex].Header}
            </span>
            <img
              className="topdrops-image"
              alt="topdrops"
              src={dropsData[dropIndex].ImageURL}
            />
            <img className="topdrops-image-overlay" alt="topdrops" />
          </td>
        </>
      );
    }
    return dropsDataArray;
  }

  useEffect(() => {
    RefreshTopDrops().then((drops) => {
      setDrops(drops);
    });
  }, []);
  return (
    <>
      <div className="topdrops mt-3">
        <div className="banner-image">
          <div className="timer-body">
            <CountdownTimer />
          </div>
        </div>
        {/* <img className="banner-image" /> */}
        <div className="userinfo-container">
          <div className="userinfo-content">
            {/* Top Row */}
            <div className="userinfo-content-row">
              {/* Profile Info */}
              <div className="profileinfo-data">
                <div className="flex-data-container align-items-flex-start w-50 pl-20">
                  <div className="flex-line">
                    <img
                      src="../media/avatarPlaceholder.svg"
                      className="userinfo-avatar"
                      alt="avatar"
                    />
                    <span className="profile-name">Sir Awesome</span>
                  </div>

                  <div className="editprofile-button">
                    <span className="editprofile-text">Edit Profile</span>
                  </div>
                </div>
                <div className="flex-data-container align-items-flex-start w-50">
                  <div className="flex-line">
                    <span className="section-text">Info</span>
                    <div className="completion-circle"></div>
                    <span className="completion-text">Complete</span>
                  </div>
                  <span className="profileinfo-text">
                    Only Mobile Games for me
                  </span>
                  <div className="flex-line">
                    <img
                      src="../media/location.svg"
                      className="location-icon"
                      alt="location-img"
                    />
                    <span className="location-text">Philadelphia, PA</span>
                  </div>
                </div>
              </div>

              {/* Collection Categories */}
              <div className="collectioncategories-data">
                <div className="flex-data-container w-100">
                  <span className="section-text">Collection Categories</span>
                  <div className="collection-categories-entries-container">
                    <div className="collection-categories-entry">
                      <span className="collection-categories-entry-text">
                        Mobile Gaming
                      </span>
                    </div>
                    <div className="collection-categories-entry">
                      <span className="collection-categories-entry-text">
                        Music
                      </span>
                    </div>
                    <div className="collection-categories-entry">
                      <span className="collection-categories-entry-text">
                        Sports
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="userinfo-content-row">
              {/* Wallet Info */}
              <div className="wallet-data">
                <div className="flex-data-container aligned-center w-50">
                  <span className="pill-header">NFTs</span>
                  <div className="wallet-data-pill">
                    <span>27</span>
                  </div>
                  <span className="pill-header">Assets</span>
                  <div className="wallet-data-pill">
                    <span>105</span>
                  </div>
                </div>
                <div className="flex-data-container aligned-center w-50">
                  <span className="pill-header">Wallet Value</span>
                  <div className="wallet-data-pill">
                    <div className="wallet-completion-circle"></div>
                    <span className="wallet-completion-text">Pending</span>
                  </div>
                  <span className="pill-header">Wazuzu Coins</span>
                  <div className="wallet-data-pill">
                    <span>1,203</span>
                  </div>
                </div>
              </div>

              {/* Top Collections */}
              <div className="topcollections-data">
                <div className="topcollections-flex-row h-30">
                  <span className="section-text">Top Collections</span>
                  <span className="topcollections-viewall-text">View All</span>
                  <img
                    className="topcollections-viewall-pointerright"
                    alt="pointer-img"
                  />
                </div>
                <div className="topcollections-flex-row h-70">
                  <div className="topcollections-collectiondetails-container">
                    <span>Tower Tycoons</span>
                    <img
                      src={TowerTycoonsIcon}
                      className="topcollections-brand-collection-icon"
                      alt="TT icon"
                      onClick={() => {
                        document.body.style.overflowY = "hidden";
                        props.setPanelOpen(true);
                      }}
                    />
                    <span className="topcollections-collectiondetails-amt">
                      99+
                    </span>
                  </div>
                  <div className="topcollections-collectiondetails-container">
                    <span>Pro ERA</span>
                    <img
                      src={ProEraIcon}
                      className="topcollections-brand-collection-icon"
                      alt="PE icon"
                    />
                    <span className="topcollections-collectiondetails-amt">
                      3
                    </span>
                  </div>
                  <div className="topcollections-collectiondetails-container">
                    <span>Beat Saber</span>
                    <img
                      src={BeatSaberIcon}
                      className="topcollections-brand-collection-icon"
                      alt="BS icon"
                    />
                    <span className="topcollections-collectiondetails-amt">
                      10
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="comingsoon">
          <span>Coming Soon!</span>
        </div>
      </div>
    </>
  );
};

export default TopDrops;
