import axios from "axios";
const axiosInst = axios.create({
  baseURL: "http://vaultservices.wazuzu.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export const updateRarity =
  (
    rngTotal: number,
    commonLow: number,
    commonHigh: number,
    uncommonLow: number,
    uncommonHigh: number,
    rareLow: number,
    rareHigh: number,
    epicLow: number,
    epicHigh: number,
    legendaryLow: number,
    legendaryHigh: number,
    relicLow: number,
    relicHigh: number
  ) =>
  async () => {
    try {
      const res = await axiosInst.put("/api/wvrarity/update", {
        RNGTotal: rngTotal,
        CommonLow: commonLow,
        CommonHigh: commonHigh,
        UncommonLow: uncommonLow,
        UncommonHigh: uncommonHigh,
        RareLow: rareLow,
        RareHigh: rareHigh,
        EpicLow: epicLow,
        EpicHigh: epicHigh,
        LegendaryLow: legendaryLow,
        LegendaryHigh: legendaryHigh,
        RelicLow: relicLow,
        RelicHigh: relicHigh,
        DateCreated: new Date().toUTCString(),
        DateModified: new Date().toUTCString(),
      });
      return res.status;
    } catch (err) {
      console.log(err);
      return 500;
    }
  };

//Returns stored rarity values to populate inputs
export const getEntry = () => async () => {
  try {
    const res = await axiosInst.get("/api/wvrarity/getentry");
    // console.log(res);
    return res;
  } catch (err) {
    console.log(err);
    return 500;
  }
};

export const testRarity = () => async () => {
  try {
    const res = await axiosInst.get("/api/wvrarity/getrarity");
    return res;
  } catch (err) {
    console.log(err);
    return 500;
  }
};
