import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getEntry,
  testRarity,
  updateRarity,
} from "../../actions/rarity.action";

const RarityCreatorContent = () => {
  const [commonLow] = useState("1");
  const [commonHigh, setCommonHigh] = useState("");
  const [uncommonLow, setUncommonLow] = useState("");
  const [uncommonHigh, setUncommonHigh] = useState("");
  const [rareLow, setRareLow] = useState("");
  const [rareHigh, setRareHigh] = useState("");
  const [epicLow, setEpicLow] = useState("");
  const [epicHigh, setEpicHigh] = useState("");
  const [legendaryLow, setLegendaryLow] = useState("");
  const [legendaryHigh, setLegendaryHigh] = useState("");
  const [relicLow, setRelicLow] = useState("");
  const [relicHigh, setRelicHigh] = useState("100");
  const [rngTotal, setRNGTotal] = useState("100");
  const [createResponse, setCreateResponse] = useState("");
  const [testRarityReturn, setTestRarityReturn] = useState("");
  const [storedRetrieveResponse, setStoredRetrieveResponse] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    //set initial current time
    let today = new Date();
    let secondsValue =
      today.getSeconds() < 10
        ? "0" + today.getSeconds().toString()
        : today.getSeconds();
    let minutesValue =
      today.getMinutes() < 10
        ? "0" + today.getMinutes().toString()
        : today.getMinutes();
    let millisecondsValue =
      today.getMilliseconds() < 10
        ? "0" + today.getMilliseconds().toString()
        : today.getMilliseconds();

    let time =
      today.getHours() +
      ":" +
      minutesValue +
      ":" +
      secondsValue +
      ":" +
      millisecondsValue;
    setCurrentTime(time);

    getEntryReturn();
  }, []);

  const checkInputValuesAreNumbers = () => {
    let commonLowResult =
      commonLow != null && commonLow !== "" && !isNaN(Number(commonLow))
        ? true
        : false;
    let commonHighResult =
      commonHigh != null && commonHigh !== "" && !isNaN(Number(commonHigh))
        ? true
        : false;

    let uncommonLowResult =
      uncommonLow != null && uncommonLow !== "" && !isNaN(Number(uncommonLow))
        ? true
        : false;
    let uncommonHighResult =
      uncommonHigh != null &&
      uncommonHigh !== "" &&
      !isNaN(Number(uncommonHigh))
        ? true
        : false;

    let rareLowResult =
      rareLow != null && rareLow !== "" && !isNaN(Number(rareLow))
        ? true
        : false;
    let rareHighResult =
      rareHigh != null && rareHigh !== "" && !isNaN(Number(rareHigh))
        ? true
        : false;

    let epicLowResult =
      epicLow != null && epicLow !== "" && !isNaN(Number(epicLow))
        ? true
        : false;
    let epicHighResult =
      epicHigh != null && epicHigh !== "" && !isNaN(Number(epicHigh))
        ? true
        : false;

    let legendaryLowResult =
      legendaryLow != null &&
      legendaryLow !== "" &&
      !isNaN(Number(legendaryLow))
        ? true
        : false;
    let legendaryHighResult =
      legendaryHigh != null &&
      legendaryHigh !== "" &&
      !isNaN(Number(legendaryHigh))
        ? true
        : false;

    let relicLowResult =
      relicLow != null && relicLow !== "" && !isNaN(Number(relicLow))
        ? true
        : false;
    let relicHighResult =
      relicHigh != null && relicHigh !== "" && !isNaN(Number(relicHigh))
        ? true
        : false;

    if (
      commonLowResult &&
      commonHighResult &&
      uncommonLowResult &&
      uncommonHighResult &&
      rareLowResult &&
      rareHighResult &&
      epicLowResult &&
      epicHighResult &&
      legendaryLowResult &&
      legendaryHighResult &&
      relicLowResult &&
      relicHighResult
    ) {
      return true;
    }
    return false;
  };

  const testRarityClick = async () => {
    let res: any = await testRarity().call(null);
    setTestRarityReturn(res.data.rarity);
    let today = new Date();
    let secondsValue =
      today.getSeconds() < 10
        ? "0" + today.getSeconds().toString()
        : today.getSeconds();
    let minutesValue =
      today.getMinutes() < 10
        ? "0" + today.getMinutes().toString()
        : today.getMinutes();
    let millisecondsValue =
      today.getMilliseconds() < 10
        ? "0" + today.getMilliseconds().toString()
        : today.getMilliseconds();

    let time =
      today.getHours() +
      ":" +
      minutesValue +
      ":" +
      secondsValue +
      ":" +
      millisecondsValue;
    setCurrentTime(time);
    //console.log(res.data.rarity);
  };

  const getEntryReturn = async () => {
    let res: any = await getEntry().call(null);
    if (res.status === 200) {
      setRNGTotal(res.data.rarities.RNGTotal);
      setCommonHigh(res.data.rarities.CommonHigh);
      setUncommonLow(res.data.rarities.UncommonLow);
      setUncommonHigh(res.data.rarities.UncommonHigh);
      setRareLow(res.data.rarities.RareLow);
      setRareHigh(res.data.rarities.RareHigh);
      setEpicLow(res.data.rarities.EpicLow);
      setEpicHigh(res.data.rarities.EpicHigh);
      setLegendaryLow(res.data.rarities.LegendaryLow);
      setLegendaryHigh(res.data.rarities.LegendaryHigh);
      setRelicLow(res.data.rarities.RelicLow);
      setRelicHigh(res.data.rarities.RelicHigh);
    } else {
      setStoredRetrieveResponse(
        "There was a problem retrieving stored values.\nPlease try again later."
      );
    }
  };

  const submitRarityValues = async () => {
    setCreateResponse("");

    if (!checkInputValuesAreNumbers()) {
      setCreateResponse("Inputs must only be positive numbers");
      return;
    }

    if (
      Number(commonLow) === 1 &&
      Number(commonLow) < Number(commonHigh) &&
      Number(commonHigh) < Number(uncommonLow) &&
      Number(uncommonLow) < Number(uncommonHigh) &&
      Number(uncommonHigh) < Number(rareLow) &&
      Number(rareLow) < Number(rareHigh) &&
      Number(rareHigh) < Number(epicLow) &&
      Number(epicLow) < Number(epicHigh) &&
      Number(epicHigh) < Number(legendaryLow) &&
      Number(legendaryLow) < Number(legendaryHigh) &&
      Number(legendaryHigh) < Number(relicLow) &&
      Number(relicHigh) === Number(rngTotal)
    ) {
      let status = await updateRarity(
        Number(rngTotal),
        Number(commonLow),
        Number(commonHigh),
        Number(uncommonLow),
        Number(uncommonHigh),
        Number(rareLow),
        Number(rareHigh),
        Number(epicLow),
        Number(epicHigh),
        Number(legendaryLow),
        Number(legendaryHigh),
        Number(relicLow),
        Number(relicHigh)
      ).call(null);
      if (status !== 200)
        setCreateResponse(
          "There was an issue updating rarity values. Please try again later."
        );
      else setCreateResponse("Rarities successfully updated.");
    } else {
      setCreateResponse(
        "Values must not overlap, and only ascending rarity values are allowed."
      );
    }
  };

  return (
    <>
      <img
        src={require("../../media/logo_wazuzudungeon.png")}
        className="raritycreatorcontent-dungeonlogo"
        alt="Wazuzu Logo"
        onClick={() => navigate("/")}
      />
      <h1 className="raritycreatorcontent-title">Set Rarity Odds</h1>
      <span className="raritycreatorcontent-instructions">
        Please enter values for each rarity and select a RNG value total.
      </span>
      <div className="raritycreatorcontent-input-container">
        <label className="raritycreatorcontent-label">RNG Total:</label>
        <div className="raritycreator-highlowcontainer">
          <select
            name="RNGTotals"
            id="raritycreator-rngtotalselect"
            className="raritycreator-rngtotalselect"
            value={rngTotal}
            onChange={(event) => {
              setRNGTotal(event.target.value);
              setRelicHigh(event.target.value);
            }}
          >
            <option value="100">100</option>
            <option value="1000">1000</option>
            <option value="10000">10000</option>
            <option value="100000">100000</option>
          </select>
        </div>
      </div>
      <div className="raritycreatorcontent-input-container">
        <label htmlFor="latitude" className="raritycreatorcontent-label">
          Common:
        </label>
        <div className="raritycreator-highlowcontainer">
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={commonLow}
            placeholder="Common Low..."
            readOnly={true}
          />
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={commonHigh}
            onChange={(event) => {
              setCommonHigh(event.target.value);
              setUncommonLow((Number(event.target.value) + 1).toString());
            }}
            placeholder="Common High..."
          />
        </div>
      </div>
      <div className="raritycreatorcontent-input-container">
        <label htmlFor="latitude" className="raritycreatorcontent-label">
          Uncommon:
        </label>
        <div className="raritycreator-highlowcontainer">
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={uncommonLow}
            onChange={(event) => setUncommonLow(event.target.value)}
            placeholder="Uncommon Low..."
            readOnly={true}
          />
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={uncommonHigh}
            onChange={(event) => {
              setUncommonHigh(event.target.value);
              setRareLow((Number(event.target.value) + 1).toString());
            }}
            placeholder="Uncommon High..."
          />
        </div>
      </div>
      <div className="raritycreatorcontent-input-container">
        <label htmlFor="longitude" className="raritycreatorcontent-label">
          Rare:
        </label>
        <div className="raritycreator-highlowcontainer">
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={rareLow}
            onChange={(event) => setRareLow(event.target.value)}
            placeholder="Rare Low..."
            readOnly={true}
          />
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={rareHigh}
            onChange={(event) => {
              setRareHigh(event.target.value);
              setEpicLow((Number(event.target.value) + 1).toString());
            }}
            placeholder="Rare High..."
          />
        </div>
      </div>
      <div className="raritycreatorcontent-input-container">
        <label htmlFor="latitude" className="raritycreatorcontent-label">
          Epic:
        </label>
        <div className="raritycreator-highlowcontainer">
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={epicLow}
            onChange={(event) => setEpicLow(event.target.value)}
            placeholder="Epic Low..."
            readOnly={true}
          />
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={epicHigh}
            onChange={(event) => {
              setEpicHigh(event.target.value);
              setLegendaryLow((Number(event.target.value) + 1).toString());
            }}
            placeholder="Epic High..."
          />
        </div>
      </div>
      <div className="raritycreatorcontent-input-container">
        <label htmlFor="latitude" className="raritycreatorcontent-label">
          Legendary:
        </label>
        <div className="raritycreator-highlowcontainer">
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={legendaryLow}
            onChange={(event) => setLegendaryLow(event.target.value)}
            placeholder="Legendary Low..."
            readOnly={true}
          />
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={legendaryHigh}
            onChange={(event) => {
              setLegendaryHigh(event.target.value);
              setRelicLow((Number(event.target.value) + 1).toString());
            }}
            placeholder="Legendary High..."
          />
        </div>
      </div>
      <div className="raritycreatorcontent-input-container">
        <label htmlFor="latitude" className="raritycreatorcontent-label">
          Relic:
        </label>
        <div className="raritycreator-highlowcontainer">
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={relicLow}
            onChange={(event) => setRelicLow(event.target.value)}
            placeholder="Relic Low..."
            readOnly={true}
          />
          <input
            type="text"
            className="raritycreatorcontent-input"
            value={rngTotal}
            // onChange={(event) => setRelicHigh(event.target.value)}
            placeholder="Relic High..."
            readOnly={true}
          />
        </div>
      </div>

      <span className="raritycreatorcontent-response">
        <strong>{storedRetrieveResponse}</strong>
      </span>

      <div
        className="raritycreatorcontent-createbutton"
        onClick={() => submitRarityValues()}
      >
        <span>Update Odds</span>
      </div>

      <span className="raritycreatorcontent-response">{createResponse}</span>

      <div
        className="raritycreatorcontent-createbutton"
        onClick={() => testRarityClick()}
      >
        <span>Test Odds</span>
      </div>

      <span className="raritycreatorcontent-response">
        Returned Rarity: <strong>{testRarityReturn}</strong> at {currentTime}
      </span>

      <br />
      <br />

      <table className="table table-striped rarity-odds-table text-white table-bordered text-center">
        <thead>
          <tr>
            <th>Type</th>
            <th>Odds (Percentage)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Common</td>
            <td>
              {((Number(commonHigh) - Number(commonLow) + 1) /
                Number(rngTotal)) *
                100}
              %
            </td>
          </tr>
          <tr>
            <td>Uncommon</td>
            <td>
              {((Number(uncommonHigh) - Number(uncommonLow) + 1) /
                Number(rngTotal)) *
                100}
              %
            </td>
          </tr>
          <tr>
            <td>Rare</td>
            <td>
              {((Number(rareHigh) - Number(rareLow) + 1) / Number(rngTotal)) *
                100}
              %
            </td>
          </tr>
          <tr>
            <td>Epic</td>
            <td>
              {((Number(epicHigh) - Number(epicLow) + 1) / Number(rngTotal)) *
                100}
              %
            </td>
          </tr>
          <tr>
            <td>Legendary</td>
            <td>
              {((Number(legendaryHigh) - Number(legendaryLow) + 1) /
                Number(rngTotal)) *
                100}
              %
            </td>
          </tr>
          <tr>
            <td>Relic</td>
            <td>
              {((Number(relicHigh) - Number(relicLow) + 1) / Number(rngTotal)) *
                100}
              %
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />
      <br />
    </>
  );
};

export default RarityCreatorContent;
