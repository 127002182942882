import axios from 'axios';
const axiosInst = axios.create({baseURL: 'http://vaultservices.wazuzu.com'})
export const getTopDrops = () => async () => {
    try{
        const res = await axiosInst.get('/api/wvtopdrops');
        return res.data;
    }
    catch(err){
        console.log(err);
    }
};