import axios, { /* AxiosError, */AxiosRequestConfig } from 'axios'
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry'
import { Dispatch } from 'react'
import { getStoredItem } from '../common/localstorage'
import { StorageKeys } from '../common/storeapp.types'
import { IAction } from '../common/storebase.types'
import { APIRes, BaseRes, DropTopType, LoginErrorData, LoginReq, RegisterReq, ResetPasswordReq } from './api.types'


const retryConfig: IAxiosRetryConfig = {}

const createAxios = () => {
  // const token = getStoredItem(StorageKeys.token)
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_LOCAL}`,
    headers: {
      /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
      // Authorization: `Bearer ${token}`
    }
  })
  axiosRetry(api, retryConfig)
  return api
}

const createRunner = () => {
  return async function runAxios<T, E = {}>(config: Partial<AxiosRequestConfig>) {
    const axios = createAxios()
    if (!axios) return undefined
    try {
      const res: APIRes<T, E> = await axios({ ...config, method: config.method ?? 'GET' })
      return res.data
      // if (res.data?.Success) {
      //   return res.data
      // } else {
      //   return undefined
      // }
    } catch (e: any) {
      console.log(e)
      return e.response.data as BaseRes<T, E>
    }
  }
}

// unauth public APIs

// export const promotional = async (data: PromotionalReq) => createRunner()({ url: '/promotion', data, method: 'POST' })

export const login = async (data: LoginReq) => await createRunner()<{ data: any }, LoginErrorData>({ url: '/auth/login', data, method: 'POST' })
export const register = async (data: RegisterReq) => await createRunner()<{ data: any }>({ url: '/auth/register', data, method: 'POST' })

// TODO: This api is public. Need to add an auth layer on backend where we can just pass in the auth token like so `Bearer ${token}`
// This will allow the backend to get the token and then use it to figure out which API's the user has access to
export const getTopDrops = async () => await createRunner()<{ data: any }>({ url: '/wvtopdrops', method: 'GET' })

export const getRewards = async () => await createRunner()<{ data: any }>({ url: '/wvrewards', method: 'GET' })
 
// TODO: Remove if this doesn't get used. This is placeholders for getting code verification on signup, also used to handle email confirmation, forgot password, etc.
// export const resendCode = async (data: { email: string }) => await createRunner()<{ Message: string }>({ url: '/unauth/resend', data, method: 'POST' })
// export const verifyCode = async (data: { code: string }) => await createRunner()<{ token: string }>({ url: '/unauth/verify', data, method: 'POST' })
// export const emailConfirmation = async (data: { email: string}) => await createRunner()<{ Message: string}>({ url: '/', data, method: 'POST'})
// export const forgotPassword = async (data: { email: string }) => await createRunner()({ url: '/unauth/forgot-password', data, method: 'POST' })
// export const resetForgottenPassword = async (data: ResetPasswordReq) => await createRunner()<{ token: string }>({ url: '/unauth/reset-password', data, method: 'POST' })



export const createFuncs = (userDispatch: Dispatch<IAction>) => {
  const runAxios = createRunner()
  return {

    // TODO: Keep this here. It is placeholder functions for interacting with the database. Useful because these functions you 
    // can call anywhere using this syntax `const {getUser} = useApi();`

    // ***** USER API *****
    // Typical setup is like this. Keeping to eventually implement and use this API
    getUserWithToken: async () => await runAxios<{ data: any, Message: string }>({ url: `/user/token`}),
    getUser: async (id: string) => await runAxios<{ data: any, Message: string }>({ url: `/user/id/${id}`}),
  }
}