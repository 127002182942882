import React, { useEffect, useState } from 'react';

interface IFilterButtons {

}

const FilterButtons = () => {
    useEffect(() => {
    }, []);

    return (
        <>
            <div className='filterbuttons-container mt-3'>
                <div className='filterbuttons-button filterbuttons-active'>
                    <span>All</span>
                </div>
                <div className='filterbuttons-button'>
                    <span>NFTs</span>
                </div>
                <div className='filterbuttons-button'>
                    <span>Assets</span>
                </div>
            </div>
        </>
    );
}

export default FilterButtons;