import React, { useEffect, useState } from "react";
import NavigationBar from "../components/navbar/navbar.component";
import TopDrops from "../components/topdrops/topdrops.component";
import SlideView from "../components/slider/slideview.component";
import CollectionPanel from "../components/collection/collectionpanel.component";
import Footer from "../components/footer/footer.component";
import FilterButtons from "../components/filterbuttons/filterbuttons.component";
import NFTInformation from "./nftinformation.view";

const Dashboard = () => {
  const [panelOpen, setPanelOpen] = useState(false);
  const [collectionTitle, setCollectionTitle] = useState("");
  const [currentView, setCurrentView] = useState("");

  useEffect(() => {}, [currentView]);

  switch (currentView) {
    case "Home":
      return (
        <>
          <div className="body-container">
            <NavigationBar setCurrentView={setCurrentView} />
            <TopDrops setPanelOpen={setPanelOpen} />
            <CollectionPanel
              title={collectionTitle}
              panelOpen={panelOpen}
              setPanelOpen={setPanelOpen}
            />
            <FilterButtons />
            <SlideView
              title="NFTs"
              setPanelOpen={setPanelOpen}
              setCollectionTitle={setCollectionTitle}
              setCurrentView={setCurrentView}
              setQRCodeOpen={() => void 0}
              setRedeemableInfoOpen={() => void 0}
              setCurrentRedeemable={() => void 0}
            />
            <SlideView
              title="Tower Tycoons"
              setPanelOpen={setPanelOpen}
              setCollectionTitle={setCollectionTitle}
              setCurrentView={setCurrentView}
              setQRCodeOpen={() => void 0}
              setRedeemableInfoOpen={() => void 0}
              setCurrentRedeemable={() => void 0}
            />
            {/* <SlideView title="Pro Era" setPanelOpen={setPanelOpen} setCollectionTitle={setCollectionTitle} setCurrentView={setCurrentView} setQRCodeOpen={() => void 0} setRedeemableInfoOpen={() => void 0} setCurrentRedeemable={() => void 0} /> */}
            <Footer />
          </div>
        </>
      );
    case "NFTInformation":
      return (
        <>
          <div className="body-container">
            <NavigationBar setCurrentView={setCurrentView} />
            <NFTInformation />
            <Footer />
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="body-container">
            <NavigationBar setCurrentView={setCurrentView} />
            <TopDrops setPanelOpen={setPanelOpen} />
            <CollectionPanel
              title={collectionTitle}
              panelOpen={panelOpen}
              setPanelOpen={setPanelOpen}
            />
            <FilterButtons />
            <SlideView
              title="NFTs"
              setPanelOpen={setPanelOpen}
              setCollectionTitle={setCollectionTitle}
              setCurrentView={setCurrentView}
              setQRCodeOpen={() => void 0}
              setRedeemableInfoOpen={() => void 0}
              setCurrentRedeemable={() => void 0}
            />
            <SlideView
              title="Tower Tycoons"
              setPanelOpen={setPanelOpen}
              setCollectionTitle={setCollectionTitle}
              setCurrentView={setCurrentView}
              setQRCodeOpen={() => void 0}
              setRedeemableInfoOpen={() => void 0}
              setCurrentRedeemable={() => void 0}
            />
            {/* <SlideView title="Pro Era" setPanelOpen={setPanelOpen} setCollectionTitle={setCollectionTitle} setCurrentView={setCurrentView} setQRCodeOpen={() => void 0} setRedeemableInfoOpen={() => void 0} setCurrentRedeemable={() => void 0} /> */}
            <Footer />
          </div>
        </>
      );
  }
};

export default Dashboard;
