import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import TowerTycoonCard from "../slider/towertycoon.card.component";
import { useHover } from '../../contexts/ui/ui.context';
import { useUserState } from '../../contexts/user/user.context';
import { getUserInventory } from '../../actions/userinventory.action';

interface INFTPopularCollection {
}

const NFTPopularCollection = (props: INFTPopularCollection) => {
    const [managers, setManagers] = useState<any>(null);
    const { hover } = useHover();
    const userState = useUserState();

    useEffect(() => {
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    adaptiveHeight: true,
                }
            }
        ]
    };

    return (
        <>
            <div className='popular-collection-body'>
                <div className='popular-collection-title-container'>
                    <h2 className='popular-collection-title'> Popular from this collection </h2>
                </div>
                <div className='popular-collection-cards-container'>
                    <div className={'popular-collection-card-flex-container '}>
                        <TowerTycoonCard brand={'Tower Tycoons'} title='Tesla Tower' level={8} isNFT={false} rarity={"Legendary"} />
                    </div>
                    <div className={'popular-collection-card-flex-container '}>
                        <TowerTycoonCard brand={'Tower Tycoons'} title='Peter Jackson' level={3} isNFT={false} rarity={"Legendary"} />
                    </div>
                    <div className={'popular-collection-card-flex-container '}>
                        <TowerTycoonCard brand={'Tower Tycoons'} title='Trinity Ghost' level={6} isNFT={false} rarity={"Legendary"} />
                    </div>
                    <div className={'popular-collection-card-flex-container '}>
                        <TowerTycoonCard brand={'Tower Tycoons'} title='Lucy Bolt' level={15} isNFT={false} rarity={"Legendary"} />
                    </div>
                </div>
            </div>
        </>
    );

}

export default NFTPopularCollection;