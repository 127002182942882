const XMLhttprequest = require("xhr2");

export function Request(method, url, body, contentType) {
  return new Promise((resolve, reject) => {
    let request = new XMLHttpRequest();
    request.open(method, url);
    if (contentType != null) {
      request.setRequestHeader("Content-Type", contentType);
    }
    if (body != null) {
      request.send(body);
    } else {
      request.send();
    }
    request.onload = () => {
      let response = request.response;
      return resolve(response);
    };
  });
}
