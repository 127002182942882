import React, { useEffect, useState } from 'react';
import { Redeemable } from '../../types/types';

interface IQRCodePanel {
    setQRCodeOpen: Function;
    currentRedeemable: Redeemable
}

const QRCodePanel = (props: IQRCodePanel) => {

    return (
        <>
            <div className='qrcode-darkbackground'>
                <div className="qrcode-panel">
                    <img className='qrcode-close-icon' onClick={() => { document.body.style.overflowY = "auto"; props.setQRCodeOpen(false); }} />
                    <img className='qrcode-img' />
                    <div className='qrcode-text-contatiner'>
                        <span className='qrcode-text'>{props.currentRedeemable.couponCode}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QRCodePanel;
