import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAccount, CreateHederaAccount } from "../../actions/hedera.action";
import { createUser } from "../../actions/user.action";

interface ICreateHedera {
    setHederaView: Function;
    registeredUsername: string;
}

const CreateHedera = (props: ICreateHedera) => {
    const router = useNavigate();
    const [createView, setCreateView] = useState("TOS");
    const [tosAccepted, setTOSAccepted] = useState(false);
    const [accountId, setAccountId] = useState("");
    const [publicKey, setPublicKey] = useState("");
    const [privateKey, setPrivateKey] = useState("");
    const [encryptedPrivateKey, setEncryptedPrivateKey] = useState("");
    const [createdSeedPhrase, setCreatedSeedPhrase] = useState<string[]>([]);

    useEffect(() => {

    }, []);

    const handleChange = (event: { target: { checked: boolean; }; }) => {
        if (event.target.checked) {
            setTOSAccepted(true);
        } else {
            setTOSAccepted(false);
        }
    };

    const validateTOS = async () => {
        if (tosAccepted) {
            //await request for hedera account creation
            var res: CreateHederaAccount | string = await createAccount();
            //console.log(res);
            if (typeof (res) === 'object') {
                setPrivateKey(res.privateKey!);
                setPublicKey(res.publicKey!);
                setAccountId(res.accountId!);
                setCreatedSeedPhrase(res.seedPhrase!);
                setEncryptedPrivateKey(res.encryptedPrivateKey!)
                setCreateView("ReturnData");
            }
            else {
                console.log(res);
            }
        }
    }

    async function returnToLogin() {
        const res = await createUser(props.registeredUsername, encryptedPrivateKey, publicKey, accountId)
        if (typeof (res) !== 'object') {
            return;
        }
        else if (res.status === 200) {
            router('/login');
        }
    }

    let listItem = createdSeedPhrase!.map((val, i) => {
        return (
            <span className="create-seedsreturn" key={i}>
                {i + 1}. {val}
            </span>
        );
    });

    switch (createView) {
        case "TOS":
            return (
                <>
                    <div className="container-panel">
                        <span className="header-text">Create Hedera Account</span>
                        <div className="tos-content">
                            <span className="title-text ta-left">Terms of Service</span>
                            <div className="tos-textcontainer">
                                <span className="tos-boldtext">1. ACCEPTANCE THE USE OF LOREM IPSUM TERMS AND CONDITIONS</span>
                                <span className="tos-bodytext">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Diam quis enim lobortis scelerisque fermentum dui faucibus in ornare. Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit.
                                    Et sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Adipiscing enim eu turpis egestas pretium aenean pharetra magna.
                                    Id leo in vitae turpis massa sed elementum tempus egestas. Adipiscing enim eu turpis egestas pretium aenean.
                                </span>
                                <span className="tos-boldtext">2. CREDIT CARD DETAILS</span>
                                <span className="tos-bodytext">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Diam quis enim lobortis scelerisque fermentum dui faucibus in ornare. Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit.
                                    Et sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Adipiscing enim eu turpis egestas pretium aenean pharetra magna.
                                    Id leo in vitae turpis massa sed elementum tempus egestas. Adipiscing enim eu turpis egestas pretium aenean.
                                </span>
                            </div>
                        </div>
                        <div className="tos-checkbox-container">
                            <input
                                type="checkbox"
                                className="tos-checkbox"
                                // value={tosAccepted}
                                onChange={handleChange}
                            ></input>
                            <span className="tos-readtext">I have read, understand, and agree to the the Terms of Service.</span>
                        </div>
                        <div className="create-button" onClick={() => validateTOS()}>
                            <span className="create-button-text">Connect</span>
                        </div>
                        <span className="create-cancel-text" onClick={() => props.setHederaView("ConnectCreateChoice")}>Cancel</span>
                    </div>
                </>
            );
        case "ReturnData":
            return (
                <>
                    <div className="container-panel">
                        <span className="header-text">Hedera Wallet Container</span>
                        <div className="">
                            <span className="title-text">Save this information in a secure location.</span>
                        </div>
                        <div className="create-content">
                            {/* Account Id */}
                            <span className="create-container-header">ACCOUNT ID</span>
                            <div className="create-returndata-container">
                                <span className="create-returndata">{accountId}</span>
                            </div>

                            {/* Public Key */}
                            <span className="create-container-header">PUBLIC KEY</span>
                            <div className="create-returndata-publickey-container">
                                <span className="create-returndata">{publicKey}</span>
                            </div>

                            {/* Private Key */}
                            <span className="create-container-header">PRIVATE KEY</span>
                            <div className="create-returndata-privatekey-container">
                                <span className="create-returndata">{privateKey}</span>
                            </div>
                        </div>
                        <span className="create-saveseed-text">
                            You are about to receive your seed phrase which will be your ONLY method of accessing your Hedera Wallet.
                            Write it down and store it in a safe place.
                        </span>
                        <div className="create-button" onClick={() => setCreateView("Phrases")}>
                            <span className="create-button-text">Obtain Seed Phrase</span>
                        </div>
                        {/* <span className="create-cancel-text">Cancel</span> */}
                    </div>
                </>
            );
        case "Phrases":
            return (
                <>
                    <div className="container-panel">
                        <span className="header-text">Back Up Your Wallet</span>
                        <div className="">
                            <span className="title-text">Save this information in a secure location.</span>
                        </div>
                        <div className="create-content">
                            {/* Account Id */}
                            <span className="create-container-header">SEED PHRASE</span>
                            <span className="create-saveseed-warning">
                                This recovery phrase is the ONLY way to recover a Hedera account. Store your recovery
                                words in a secure location, such as a safe. It is recommended to save your account ID
                                along with these recovery words.
                            </span>
                            <div className="create-seedsreturn-container">
                                {listItem}
                            </div>
                        </div>
                        <div className="create-button" onClick={() => returnToLogin()}>
                            <span className="create-button-text">Confirm</span>
                        </div>
                        {/* <span className="create-cancel-text">Cancel</span> */}
                    </div>
                </>
            );
        default:
            return (
                <>

                </>
            );
    }
}

export default CreateHedera;