import React, { Component, useEffect, useState } from 'react';
import { Redeemable } from '../../types/types';

interface IRedeemables {
    brand: string;
    title: string;
    setQRCodeOpen: Function;
    setRedeemableInfoOpen: Function;
    setCurrentRedeemable: Function;
}

const Redeemables = (props: IRedeemables) => {

    useEffect(() => {

    }, []);

    //TODO placeholder replace with actual data
    let couponCode = "";
    if (props.brand.toLowerCase() == "freshpet") {
        couponCode = "xQpfeast";
    }
    else {
        couponCode = "xYzlaPass"
    }

    let redeemable: Redeemable = {
        brand: props.brand,
        title: props.title,
        couponCode: couponCode
    };


    //dynamically add classes by name, will be replaced later by loading from url
    let classesRedeemablesImage = ['redeemables-brand-image', props.brand.toLowerCase().replace(/\s/g, '')].join(' ');

    return (
        <>
            <div className="redeemables-body">
                <img className={classesRedeemablesImage} />
                <div className="redeemables-title-container" >
                    <span className="redeemables-title-text" >{props.title}</span>
                    <img className="redeemables-da-icon" onClick={() => { document.body.style.overflowY = "hidden"; props.setRedeemableInfoOpen(true); props.setCurrentRedeemable(redeemable); }} />
                </div>
                <div className="redeemables-redeem-container" onClick={() => { document.body.style.overflowY = "hidden"; props.setQRCodeOpen(true); props.setCurrentRedeemable(redeemable); }}>
                    <span className="redeemables-redeem-text">Redeem</span>
                </div>
            </div>
        </>
    );
}

export default Redeemables;