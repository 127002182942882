import React, { useEffect, useState } from "react";
import TowerTycoonCard from "../slider/towertycoon.card.component";
import ProEraCard from "../slider/proera.card.component";
import { useUserState } from "../../contexts/user/user.context";
import { getUserInventory } from "../../actions/userinventory.action";

interface ICollectionPanel {
  title: string;
  panelOpen: boolean;
  setPanelOpen: Function;
}

const CollectionPanel = (props: ICollectionPanel) => {
  const [managers, setManagers] = useState<any>(null);
  const { user } = useUserState();

  const getWazuzuCards = async (userId: string) => {
    var managerData = await getUserInventory(userId).call(null);
    return managerData.map((m: any) => (
      <TowerTycoonCard
        brand={"Tower Tycoons"}
        isNFT={false}
        rarity={m.Rarity}
        title={m.Name}
        level={m.Level}
        key={m.WZId}
      ></TowerTycoonCard>
    ));
  };

  useEffect(() => {
    //console.log(userState.user?.username);
    if (managers == null && user !== undefined && user !== null) {
      getWazuzuCards(user?.username!).then((data) => {
        setManagers(data);
      });
    }
  }, [managers, user]);

  if (props.panelOpen) {
    switch (props.title) {
      case "Tower Tycoons":
        return (
          <>
            <div className="collection-dark-background">
              <div className="collection-panel">
                <img
                  className="close-icon"
                  onClick={() => {
                    document.body.style.overflowY = "auto";
                    props.setPanelOpen(false);
                  }}
                  alt="close-icon"
                />
                <span className="collection-title">
                  {props.title} Collection
                </span>
                <div className="collection-filter-container">
                  <div className="collection-filter-button">
                    <span className="filter-text">All</span>
                  </div>
                  <div className="collection-filter-button">
                    <span className="filter-text">NFTs</span>
                  </div>
                  <div className="collection-filter-button">
                    <span className="filter-text">Assets</span>
                  </div>
                </div>
                <div className="collection-content">{managers}</div>
              </div>
            </div>
          </>
        );
      //   case "Pro Era":
      //     return (
      //       <>
      //         <div className="collection-dark-background">
      //           <div className="collection-panel">
      //             <img
      //               className="close-icon"
      //               onClick={() => {
      //                 document.body.style.overflowY = "auto";
      //                 props.setPanelOpen(false);
      //               }}
      //               alt="close-icon"
      //             />
      //             <span className="collection-title">
      //               {props.title} Collection
      //             </span>
      //             <div className="collection-filter-container">
      //               <div className="collection-filter-button">
      //                 <span className="filter-text">All</span>
      //               </div>
      //               <div className="collection-filter-button">
      //                 <span className="filter-text">NFTs</span>
      //               </div>
      //               <div className="collection-filter-button">
      //                 <span className="filter-text">Assets</span>
      //               </div>
      //             </div>
      //             <div className="collection-content">
      //               <ProEraCard
      //                 brand={"Pro Era"}
      //                 title="Carbon Grip"
      //                 level={4}
      //                 rarity={"Epic"}
      //               />
      //               <ProEraCard
      //                 brand={"Pro Era"}
      //                 title="Steelers Helmet"
      //                 level={15}
      //                 rarity={"Common"}
      //               />
      //               <ProEraCard
      //                 brand={"Pro Era"}
      //                 title="Ravens Stadium"
      //                 level={115}
      //                 rarity={"Legendary"}
      //               />
      //             </div>
      //           </div>
      //         </div>
      //       </>
      //     );
      default:
        return null;
    }
  } else {
    return null;
  }
};

export default CollectionPanel;
