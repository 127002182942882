import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ICreateConnectChoiceHedera {
    setHederaView: Function
}

const CreateConnectChoiceHedera = (props: ICreateConnectChoiceHedera) => {
    const router = useNavigate();

    useEffect(() => {

    }, []);

    function returnToLogin() {
        router('/login');
    }

    return (
        <>
            <div className="container-panel">
                <span className="header-text">Hedera Integration</span>
                <div className="createconnect-button center-buttons" onClick={() => props.setHederaView("Connect")}>
                    <span>Connect Hedera Account</span>
                </div>
                <div className="createconnect-button" onClick={() => props.setHederaView("Create")}>
                    <span>Create Hedera Account</span>
                </div>
                <span className="back-to-login-text" onClick={() => returnToLogin()}>Return to Login</span>
            </div>
        </>
    );
}

export default CreateConnectChoiceHedera;