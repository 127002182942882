import React from "react";
import Countdown from "react-countdown";

const CountdownTimer = () => {
  const renderer = ({
    hours,
    minutes,
    seconds,
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    var hoursConvert = hours.toString();
    var minutesConvert = minutes.toString();
    var secondsConvert = seconds.toString();

    if (hours < 10) {
      hoursConvert = "0" + hoursConvert;
    }
    if (minutes < 10) {
      minutesConvert = "0" + minutesConvert;
    }
    if (seconds < 10) {
      secondsConvert = "0" + secondsConvert;
    }

    return (
      //   <span className="countdown-text">
      //     {hoursConvert}:{minutesConvert}:{secondsConvert}
      //   </span>
      <span className="countdown-text">12:00:00</span>
    );
  };

  return (
    <>
      {/* Set for 12 hours */}
      <Countdown date={Date.now() + 4.32e7} renderer={renderer} />
    </>
  );
};

export default CountdownTimer;
