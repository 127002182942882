import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ConnectHedera from "../components/hederaregistration/connecthedera.component";
import CreateConnectChoiceHedera from "../components/hederaregistration/createconnectchoicehedera.component";
import CreateHedera from "../components/hederaregistration/createhedera.component";

interface userInfo {
  registerUsername: string
}

export default function HederaRegistration() {
  const location = useLocation();
  const [hederaView, setHederaView] = useState("ConnectCreateChoice");

  let { registerUsername } = location.state as userInfo;
  //console.log(registerUsername);

  useEffect(() => {
  }, []);

  switch (hederaView) {
    case "ConnectCreateChoice":
      return (
        <>
          <div className="background">
            <CreateConnectChoiceHedera setHederaView={setHederaView} />
          </div>
        </>
      );
    case "Create":
      return (
        <>
          <div className="background">
            <CreateHedera setHederaView={setHederaView} registeredUsername={registerUsername} />
          </div>
        </>
      );
    case "Connect":
      return (
        <>
          <div className="background">
            <ConnectHedera setHederaView={setHederaView} registeredUsername={registerUsername} />
          </div>
        </>
      );
    default: //ConnectCreateChoice By Default
      return (
        <>
          <div className="background">
            <CreateConnectChoiceHedera setHederaView={setHederaView} />
          </div>
        </>
      );
  }
}