import React, { useEffect, useState } from 'react';
import { Redeemable } from '../../types/types';

interface IRedeemableInfoPanel {
    setRedeemableInfoOpen: Function;
    setQRCodeOpen: Function;
    currentRedeemable: Redeemable
}

const RedeemableInfoPanel = (props: IRedeemableInfoPanel) => {

    let description = "";
    if (props.currentRedeemable.brand.toLowerCase().replace(/\s/g, '') == "freshpet") {
        description = "Treat your furry friend to the delicious, healthy food they deserve. Save 15% on your next Freshpet Feast!";
    }
    else {
        description = "Enjoy a free day pass for any LA Fitness!";
    }

    let classesRedeemablesImage = ['redeemableinfo-brand-img', props.currentRedeemable.brand.toLowerCase().replace(/\s/g, '')].join(' ');

    return (
        <>
            <div className='redeemableinfo-darkbackground'>
                <div className="redeemableinfo-panel">
                    <img className='redeemableinfo-close-icon' onClick={() => { document.body.style.overflowY = "auto"; props.setRedeemableInfoOpen(false); }} />
                    <img className={classesRedeemablesImage} />
                    <div className='redeemableinfo-text-contatiner'>
                        <span className='redeemableinfo-title'>{props.currentRedeemable.title !== undefined ? props.currentRedeemable.title : ""}</span>
                        <span className='redeemableinfo-description-text'>{description}</span>
                        <span className='redeemableinfo-expiration-text'>Expires 1/30/2024</span>
                    </div>
                    <div className='redeemableinfo-redeem' onClick={() => { props.setQRCodeOpen(true); props.setRedeemableInfoOpen(false); }}>
                        <span className='redeemableinfo-text'>Redeem</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RedeemableInfoPanel;
