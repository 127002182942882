import axios from 'axios';
const axiosInst = axios.create({
    baseURL: 'http://vaultservices.wazuzu.com',
    headers: {
        'Content-Type': 'application/json',
    },
})

export const getUserInventory = (userId: string) => async () => {
    const params = {
        userId: userId,
    };

    try {
        const res = await axiosInst.get('/api/wvuserrewards/' + userId);
        return res.data;
    }
    catch (err) {
        console.log(err);
    }
};