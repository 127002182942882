import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHover } from "../../contexts/ui/ui.context";

var username = 'Sir Awesome'; //get username here
// var hasNotifications = false; //check for notifications here
// var notificationCount = 0; //check for notifications here

// async function RefreshNotifications(){
//     var notificationData = [];
//     notificationData = await getNotifications().call(null);
//     if(notificationData.length == 0){
//         hasNotifications = false;
//         notificationCount = 0;
//     }
//     else{
//         hasNotifications = true;
//         notificationCount = notificationData.length;
//     }
// }

interface INavigationBar {
    setCurrentView: Function
}

const NavigationBar = (props: INavigationBar) => {
    const { setHover } = useHover();

    return (
        <>
            <div className="topnav">
                <div className="topnav-content">
                    <a className="topnav-logo-container" onClick={() => { props.setCurrentView("Home"); setHover(false) }}>
                        <img className="topnav-logo" alt="Wazuzu Dungeon Logo" />
                    </a>
                    <a className="topnav-search-container">
                        <input className="topnav-search" type="text" placeholder="Search Collections & More..." />
                        <img className="topnav-search-icon" alt="Search Glass"></img>
                        {/* <img className="topnav-search-arrow" alt="Down Arrow"></img> */}
                    </a>
                    <div className="topnav-notifs-profile-container">
                        {/* Notifications Bell */}
                        <a className="topnav-notifications">
                            <img className="topnav-notification-icon" />
                            {/* <img className="topnav-notification-button"/>
                        <p className="topnav-notification-count">{notificationCount}</p> */}
                        </a>

                        {/* Welcome Back User Text */}
                        <a className="topnav-user">
                            <p><b>Welcome Back</b><br />{username}</p>
                            <img className="topnav-user-icon" alt="Profile Picture" />
                            <img src='../media/btn_hamburger.svg' className="topnav-hamburger" alt="Menu" />
                        </a>

                        {/* User Profile Image */}

                        {/* Hamburger Menu Image */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavigationBar;