import React, { useEffect } from "react";
import RarityCreatorContent from "../components/raritycreator/raritycreatorcontent.component";

interface IRarityCreator {}

const RarityCreator = (props: IRarityCreator) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="markercreator-body">
        <RarityCreatorContent />
      </div>
    </>
  );
};

export default RarityCreator;
