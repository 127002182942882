import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserDispatch } from "../contexts/user/user.context";
import { Request } from "../tools/api-request";
import { UserActions } from "../contexts/user/user.context";

export default function Login() {
  const router = useNavigate();
  // const inputRef = React.useRef();
  const [state] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const userDispatch = useUserDispatch();

  useEffect(function () {}, [state, errorMessage]);

  class User {
    username: string;
    password: string;
    constructor(username: string, password: string) {
      this.username = username;
      this.password = password;
    }
  }

  function submitLogin() {
    //take inputs from html
    let usernameInput = document.querySelector(
      "#username"
    ) as HTMLInputElement | null;
    let passwordInput = document.querySelector(
      "#password"
    ) as HTMLInputElement | null;

    //assign value to variable if exists
    let username = usernameInput?.value!;
    let password = passwordInput?.value!;

    //check username and password with regex
    let valid = true;
    let usernameRegExp = new RegExp(/^[a-zA-Z0-9@\-.+_]{6,}$/);
    let passwordRegExp = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
    );

    if (
      usernameRegExp.test(username) === false ||
      passwordRegExp.test(password) === false
    ) {
      valid = false;
      setErrorMessage(
        "Error: This Username and/or Password is invalid, please try again!"
      );
    }

    if (username === "" || password === "") {
      valid = false;
      setErrorMessage("Error: Empty fields, please try again!");
    }

    //send info to backend if passed all checks
    if (valid) {
      let user = new User(username, password);

      //user pool management login here
      Request(
        "POST",
        "http://vaultservices.wazuzu.com/api/auth/login",
        JSON.stringify(user),
        "application/json"
      )
        .then((res) => {
          res = JSON.parse(res);
          if (res.user != null) {
            setErrorMessage("");

            //if no connected hedera, force user to connect
            if (res.errorMessage === "Missing Hedera") {
              router("/hedera", {
                state: {
                  registerUsername: res.user.AccessToken.payload.username,
                },
              });
            } else {
              userDispatch({
                type: UserActions.login,
                payload: res.user.AccessToken.jwtToken,
              });
              router("/dashboard");
            }
          } else {
            setErrorMessage(
              "Error: This Username and Password combination is invalid, please try again!"
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  //Handle Connect Hedera Click
  // function submitConnectHedera() {
  //   //take inputs from html
  //   let usernameInput = document.querySelector(
  //     "#username"
  //   ) as HTMLInputElement | null;
  //   let passwordInput = document.querySelector(
  //     "#password"
  //   ) as HTMLInputElement | null;

  //   //assign value to variable if exists
  //   let username = usernameInput?.value!;
  //   let password = passwordInput?.value!;

  //   //check username and password with regex
  //   let valid = true;
  //   let usernameRegExp = new RegExp(/^[a-zA-Z0-9@\-.+_]{6,}$/);
  //   let passwordRegExp = new RegExp(
  //     /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
  //   );

  //   if (
  //     usernameRegExp.test(username) === false ||
  //     passwordRegExp.test(password) === false
  //   ) {
  //     valid = false;
  //     setErrorMessage(
  //       "Error: This Username and/or Password is invalid, please try again!"
  //     );
  //   }

  //   if (username === "" || password === "") {
  //     valid = false;
  //     setErrorMessage("Error: Empty fields, please try again!");
  //   }

  //   //send info to backend if passed all checks
  //   if (valid) {
  //     let user = new User(username, password);

  //     //user pool management login here
  //     Request(
  //       "POST",
  //       "http://vaultservices.wazuzu.com/api/auth/login",
  //       JSON.stringify(user),
  //       "application/json"
  //     )
  //       .then((res) => {
  //         res = JSON.parse(res);
  //         if (res.user != null) {
  //           setErrorMessage("");
  //           // userDispatch({ type: UserActions.login, payload: res.user.AccessToken.jwtToken });
  //           router("/hedera", {
  //             state: {
  //               registerUsername: res.user.AccessToken.payload.username,
  //             },
  //           });
  //         } else {
  //           setErrorMessage(
  //             "Error: This Username and Password combination is invalid, please try again!"
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      submitLogin();
    }
  };

  function toRegister() {
    router("/register");
  }

  return (
    <>
      <div id="login-background">
        <div className="item-box">
          <div className="image-heading mb-4">
            <h2 className="heading-text"> </h2>
          </div>
          <div id="input-box">
            <input
              id="username"
              type="text"
              placeholder="Username"
              className="input-field mb-4"
              onKeyDown={handleKeyDown}
            ></input>
            <input
              id="password"
              type="password"
              placeholder="Password"
              className="input-field mb-5"
              onKeyDown={handleKeyDown}
            ></input>
          </div>
          <div>
            <span className="error-text">{errorMessage}</span>
            <button
              id="login-button"
              className="big-event-button mb-5"
              onClick={submitLogin}
            >
              Login
            </button>
            {/* <button
              id="login-button"
              className="big-event-button"
              onClick={submitConnectHedera}
            >
              Connect Hedera
            </button> */}
          </div>
          <div id="footer" className="footer-text">
            <span>Forgot password?</span>
            <span onClick={toRegister}>
              Don't have an account? Register Here!
            </span>
          </div>
          <div />
        </div>
      </div>
    </>
  );
}
