import React, { useEffect, useState } from 'react';
import { useHover } from '../../contexts/ui/ui.context';
import TowerTycoonsIcon from "../../media/towertycoons-icon.svg";
import NFTDetails from './NFTDetails.card.component';

interface ITowerTycoonCard {
    brand: string;
    title: string;
    level: number;
    isNFT: boolean;
    rarity: string;
    setCurrentView?: Function;
}

const TowerTycoonCard = (props: ITowerTycoonCard) => {
    const [display, setDisplay] = useState(false);
    const { setHover } = useHover();

    useEffect(() => {
    }, []);

    let rarity = "";
    if (props.level <= 3) {
        rarity = "common";
    }
    else if (props.level <= 7) {
        rarity = "rare";
    }
    else if (props.level <= 11) {
        rarity = "epic";
    }
    else if (props.level >= 11) {
        rarity = "legendary";
    }
    else {
        rarity = "common";
    }

    //dynamically add classes by name, will be replaced later by loading from url
    let classesCardImage = ['tt-card-manager-image', props.title.replace(/\s/g, '')].join(' ');
    let classesCardBackground = ['tt-card-background', rarity].join(' ');

    if (props.isNFT) {
        return (
            <>
                <div className={classesCardBackground}
                    onMouseEnter={() => { setHover(true); setDisplay(true) }}
                >
                    <img src={TowerTycoonsIcon} className="brand-icon" />
                    <img className={classesCardImage} />
                    <div className="tt-card-managername-container">
                        <span className="tt-card-managername-text">{props.title}</span>
                    </div>
                    <div className="card-levelnumber-container">
                        <div className='card-levelnumber-flex-container'>
                            <span className="card-levelnumber-text"> {props.level} </span>
                        </div>
                    </div>
                </div>
                {display && (
                    <NFTDetails brand={props.brand} title={props.title} level={props.level} setDisplay={setDisplay} rarity={props.rarity} setCurrentView={props.setCurrentView!} />
                )}
            </>
        );
    }
    else {
        return (
            <>
                <div className={classesCardBackground}>
                    <img src={TowerTycoonsIcon} className="brand-icon" />
                    <img className={classesCardImage} />
                    <div className="tt-card-managername-container">
                        <span className="tt-card-managername-text">{props.title}</span>
                    </div>
                    <div className="card-levelnumber-container">
                        <div className='card-levelnumber-flex-container'>
                            <span className="card-levelnumber-text"> {props.level} </span>
                        </div>
                    </div>
                </div>
            </>
        );
    }


}

export default TowerTycoonCard;