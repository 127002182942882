import axios from 'axios';
const axiosInst = axios.create({
    baseURL: 'http://vaultservices.wazuzu.com',
    headers: {
        'Content-Type': 'application/json',
    },
})

export const getIndividualViewData = (wzid: string) => async () => {
    try {
        console.log(wzid);
        const res = await axiosInst.get('/api/wvuserrewards/individual/' + wzid);

        return res.data;
    }
    catch (err) {
        console.log(err);
    }
};