import React, { Component, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlayerInventory from "../playerinventory.component";
import TowerTycoonsIcon from "../media/towertycoons-icon.svg";
import { postMarker } from "../../actions/markers.action";

const MarkerCreatorContent = () => {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [markerName, setMarkerName] = useState("");
  const [createResponse, setCreateResponse] = useState("");

  const navigate = useNavigate();

  const handleLongitudeInputChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    const regex = /^-?\d*\.?\d*$/; // regular expression to match numbers, decimals and negative sign
    if (regex.test(inputValue)) {
      setLongitude(inputValue);
    } else {
      //setLongitude("");
    }
  };

  const handleLatitudeInputChange = (event: { target: { value: any } }) => {
    const inputValue = event.target.value;
    const regex = /^-?\d*\.?\d*$/; // regular expression to match numbers, decimals and negative sign
    if (regex.test(inputValue)) {
      setLatitude(inputValue);
    } else {
      //setLongitude("");
    }
  };

  const latitudeLongitudeCheck = (lat: string, long: string) => {
    let latValid =
      isFinite(Number(lat)) && Math.abs(Number(lat)) <= 90 ? true : false;
    let longValid =
      isFinite(Number(long)) && Math.abs(Number(long)) <= 180 ? true : false;

    if (latValid && longValid) return true;
    else return false;
  };

  const submitMarker = async () => {
    setCreateResponse("");
    if (
      latitude.length > 0 &&
      longitude.length > 0 &&
      markerName.length > 0 &&
      latitudeLongitudeCheck(latitude, longitude)
    ) {
      let status = await postMarker(latitude, longitude, markerName).call(null);
      if (status != 200)
        setCreateResponse(
          "There was an issue creating marker. Please try again later."
        );
      else setCreateResponse("Marker successfully created.");
    }
  };

  return (
    <>
      <img
        src={require("../../media/logo_wazuzudungeon.png")}
        className="markercreatorcontent-dungeonlogo"
        alt="Wazuzu Logo"
        onClick={() => navigate("/")}
      />
      <h1 className="markercreatorcontent-title">Marker Creator</h1>
      <span className="markercreatorcontent-instructions">
        Please enter a name and valid latitude/longitude coordinates to create a
        new marker.
      </span>
      <div className="markercreatorcontent-input-container">
        <label htmlFor="markername" className="markercreatorcontent-label">
          Marker Name:
        </label>
        <input
          type="text"
          id="markername"
          className="markercreatorcontent-input"
          value={markerName}
          onChange={(event) => setMarkerName(event.target.value)}
        />
      </div>
      <div className="markercreatorcontent-input-container">
        <label htmlFor="latitude" className="markercreatorcontent-label">
          Latitude:
        </label>
        <input
          type="text"
          className="markercreatorcontent-input"
          value={latitude}
          onChange={handleLatitudeInputChange}
        />
      </div>
      <div className="markercreatorcontent-input-container">
        <label htmlFor="longitude" className="markercreatorcontent-label">
          Longitude:
        </label>
        <input
          type="text"
          className="markercreatorcontent-input"
          value={longitude}
          onChange={handleLongitudeInputChange}
        />
      </div>

      <div
        className="markercreatorcontent-createbutton"
        onClick={() => submitMarker()}
      >
        <span>Create</span>
      </div>

      <span className="markercreatorcontent-response">{createResponse}</span>
    </>
  );
};

export default MarkerCreatorContent;
