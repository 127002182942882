import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Account, recover } from "../../actions/hedera.action";
import { createUser } from "../../actions/user.action";

interface IConnectHedera {
    setHederaView: Function
    registeredUsername: string;
}

const ConnectHedera = (props: IConnectHedera) => {
    const router = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [seedPhrase1, setSeedPhrase1] = useState("");
    const [seedPhrase2, setSeedPhrase2] = useState("");
    const [seedPhrase3, setSeedPhrase3] = useState("");
    const [seedPhrase4, setSeedPhrase4] = useState("");
    const [seedPhrase5, setSeedPhrase5] = useState("");
    const [seedPhrase6, setSeedPhrase6] = useState("");
    const [seedPhrase7, setSeedPhrase7] = useState("");
    const [seedPhrase8, setSeedPhrase8] = useState("");
    const [seedPhrase9, setSeedPhrase9] = useState("");
    const [seedPhrase10, setSeedPhrase10] = useState("");
    const [seedPhrase11, setSeedPhrase11] = useState("");
    const [seedPhrase12, setSeedPhrase12] = useState("");

    useEffect(() => {

    }, []);

    const validateSeedPhrase = async () => {
        if (
            seedPhrase1.length > 0 &&
            seedPhrase2.length > 0 &&
            seedPhrase3.length > 0 &&
            seedPhrase4.length > 0 &&
            seedPhrase5.length > 0 &&
            seedPhrase6.length > 0 &&
            seedPhrase7.length > 0 &&
            seedPhrase8.length > 0 &&
            seedPhrase9.length > 0 &&
            seedPhrase10.length > 0 &&
            seedPhrase11.length > 0 &&
            seedPhrase12.length > 0
        ) {
            //Make seed phrase wallet connect request
            const res: Account[] | string = await recover(`${seedPhrase1} ${seedPhrase2} ${seedPhrase3} ${seedPhrase4} ${seedPhrase5} ${seedPhrase6} ${seedPhrase7} ${seedPhrase8} ${seedPhrase9} ${seedPhrase10} ${seedPhrase11} ${seedPhrase12}`);
            //console.log(res);
            if (res[0] instanceof Account) { //Not sure what needs to occur if multiple are returned, or if multiple are even returned
                const resCreateUser = await createUser(props.registeredUsername, res[0].encryptedPrivateKey!, res[0].publicKey!, res[0].accountId!)
                if (typeof (resCreateUser) !== 'object') {
                    setErrorMessage("There was a problem connecting Hedera. Check seedphrase and try again.")
                    return;
                }
                else if (resCreateUser.status === 200) {
                    router('/login');
                }
            }
        }
        else {
            setErrorMessage("Please fill in each input with a phrase.")
        }
    }

    return (
        <>
            <div className="container-panel">
                <span className="header-text">Hedera Integration</span>
                <div className="connect-content">
                    <span className="title-text">Enter your 12 word phrase</span>
                    <input type="text" placeholder="1" className="connect-phrase-input" onChange={(val) => setSeedPhrase1(val.currentTarget.value)}></input>
                    <input type="text" placeholder="2" className="connect-phrase-input" onChange={(val) => setSeedPhrase2(val.currentTarget.value)}></input>
                    <input type="text" placeholder="3" className="connect-phrase-input" onChange={(val) => setSeedPhrase3(val.currentTarget.value)}></input>
                    <input type="text" placeholder="4" className="connect-phrase-input" onChange={(val) => setSeedPhrase4(val.currentTarget.value)}></input>
                    <input type="text" placeholder="5" className="connect-phrase-input" onChange={(val) => setSeedPhrase5(val.currentTarget.value)}></input>
                    <input type="text" placeholder="6" className="connect-phrase-input" onChange={(val) => setSeedPhrase6(val.currentTarget.value)}></input>
                    <input type="text" placeholder="7" className="connect-phrase-input" onChange={(val) => setSeedPhrase7(val.currentTarget.value)}></input>
                    <input type="text" placeholder="8" className="connect-phrase-input" onChange={(val) => setSeedPhrase8(val.currentTarget.value)}></input>
                    <input type="text" placeholder="9" className="connect-phrase-input" onChange={(val) => setSeedPhrase9(val.currentTarget.value)}></input>
                    <input type="text" placeholder="10" className="connect-phrase-input" onChange={(val) => setSeedPhrase10(val.currentTarget.value)}></input>
                    <input type="text" placeholder="11" className="connect-phrase-input" onChange={(val) => setSeedPhrase11(val.currentTarget.value)}></input>
                    <input type="text" placeholder="12" className="connect-phrase-input" onChange={(val) => setSeedPhrase12(val.currentTarget.value)}></input>
                </div>
                <span className="connect-error-text">{errorMessage}</span>
                <div className="connect-button" onClick={() => validateSeedPhrase()}>
                    <span className="connect-button-text">Connect</span>
                </div>
                <span className="connect-cancel-text" onClick={() => props.setHederaView("ConnectCreateChoice")}>Cancel</span>
            </div>
        </>
    );
}

export default ConnectHedera;