import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserDispatch } from "../contexts/user/user.context";
import { Request } from '../tools/api-request';


export default function Register() {
  const router = useNavigate();
  const [state] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(function () { }, [state]);

  class Registration {
    username: string;
    email: string;
    password: string;
    repassword: string;
    constructor(username: string, email: string, password: string, repassword: string) {
      this.username = username;
      this.email = email;
      this.password = password;
      this.repassword = repassword;
    }
  }

  function submitRegistration() {
    //take inputs from html
    let usernameInput = document.querySelector('#username') as HTMLInputElement | null;
    let emailInput = document.querySelector('#email') as HTMLInputElement | null;
    let passwordInput = document.querySelector('#password') as HTMLInputElement | null;
    let repasswordInput = document.querySelector('#repassword') as HTMLInputElement | null;

    //assign value to variable if exists
    let username = usernameInput?.value!;
    let email = emailInput?.value!;
    let password = passwordInput?.value!;
    let repassword = repasswordInput?.value!;

    //check username, email and password with regex
    let valid = true;
    let usernameRegExp = new RegExp(/^[a-zA-Z0-9@\-.+_]{6,}$/);
    let emailRegExp = new RegExp(/^[a-zA-Z0-9]+([\-._]*[a-zA-Z0-9]+)*\@[a-zA-Z0-9]+([\-._]*[a-zA-Z0-9]+)*.[a-zA-Z0-9]+$/);
    let passwordRegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);

    if (password != repassword) {
      valid = false;
      setErrorMessage('Error: Passwords do not match, please try again!');
    }
    if (usernameRegExp.test(username) == false || passwordRegExp.test(password) == false) {
      valid = false;
      setErrorMessage('Error: Username and Password must be valid.');
    }
    if (emailRegExp.test(email) == false) {
      valid = false;
      setErrorMessage('Error: Please enter a valid email address!');
    }
    if (username == '' || password == '' || repassword == '' || email == '') {
      valid = false;
      setErrorMessage('Error: Empty fields, please try again!');
    }

    //send info to backend if passed all checks
    if (valid) {
      let registration = new Registration(username, email, password, repassword);

      //user pool management registration here
      Request('POST', 'http://vaultservices.wazuzu.com/api/auth/register', JSON.stringify(registration), 'application/json')
        .then((res) => {
          if (res != 'error') {
            router('/hedera', { state: {registerUsername: username} });
          } else {
            setErrorMessage('Error: Unable to register new user, please try again!');
          }
        }).catch((error) => {
          console.log(error);
          setErrorMessage('Error: Unable to register new user, please try again!');
        });
    }
  }

  function returnToLogin() {
    router('/login');
  }

  return (
    <>
      <body>
        <div id="login-background">
          <div className="item-box">
            <div className="image-heading">
              <h2 className="heading-text"></h2>
            </div>
            <div id="input-box">
              <input id="username" type="text" placeholder="Username" className="input-field"></input>
              <input id="email" type="email" placeholder="Email" className="input-field"></input>
              <input id="password" type="password" placeholder="Password" className="input-field"></input>
              <input id="repassword" type="password" placeholder="Re-enter Password" className="input-field"></input>
            </div>
            <div>
              <span className="error-text">{errorMessage}</span>
              <button id="login-button" className="big-event-button" onClick={submitRegistration}>Register</button>
            </div>
            <div className="footer-text" id="footer">
              <span id="return-login" onClick={returnToLogin}>Return to Login</span>
            </div>
            <div />
          </div>
        </div>
      </body>
    </>
  );
}