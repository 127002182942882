import React, { PropsWithChildren, useState } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import Login from "./views/login.view";
import Register from "./views/registration.view";
import Dashboard from "./views/dashboard.view";
import MarkerCreator from "./views/markercreator.view";
import ManagerInspect from "./components/managerinspect.component";
import { UIContext } from "./contexts/ui/ui.context";
import { UserProvider } from "./contexts/user/user.context";
import { ApiProvider } from "./contexts/api/api.context";
import { RoutesPaths } from "./common/routes";

// Style sheets
import "./styles/main.style.css";
import "./styles/nftinformation.style.css";
import "./styles/topdrops.style.css";
import "./styles/slideview.style.css";
import "./styles/collection.style.css";
import "./styles/footer.style.css";
import "./styles/countdown.style.css";
import "./styles/filterbuttons.style.css";
import "./styles/markercreator.style.css";
import "./styles/raritycreator.style.css";
import "./styles/hederaregistration.style.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import HederaRegistration from "./views/hederaregistration.view";
import RarityCreator from "./views/raritycreator.view";
import RelicRewards from "./views/relicrewards.view";

export const RootProviders: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ApiProvider>
      <UserProvider>
        <Router>{children}</Router>
      </UserProvider>
    </ApiProvider>
  );
};

const useAuth = () => {
  // TODO: Uncomment and use this to verify user exists and user token exists.
  // If exists, we can render the Outlet, aka default route...otherwise render login
  // const user = useUserState();
  // return user && user.token;
  return false;
};

const ProtectedRoutes: React.FC = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Login />;
};

const App: React.FC = () => {
  const [hover, setHover] = useState(false);

  return (
    <UIContext.Provider value={{ hover, setHover }}>
      <RootProviders>
        <Routes>
          {/* *********** PROTECTED ROUTES *********** */}
          <Route element={<ProtectedRoutes />}>
            <Route element={<Admin />} path={RoutesPaths.Admin} />
            <Route element={<Dashboard />} path={RoutesPaths.Default} />
          </Route>

          {/* *********** PUBLIC ROUTES *********** */}
          <Route path={RoutesPaths.Default} />
          <Route element={<Login />} path={RoutesPaths.Login} />
          <Route element={<Register />} path={RoutesPaths.Register} />
          <Route element={<HederaRegistration />} path={RoutesPaths.Hedera} />
          <Route element={<Dashboard />} path={RoutesPaths.Dashboard} />
          <Route
            element={<ManagerInspect />}
            path={RoutesPaths.ViewIndividual}
          />
          <Route element={<MarkerCreator />} path={RoutesPaths.MarkerCreator} />
          <Route element={<RarityCreator />} path={RoutesPaths.RarityCreator} />
          <Route element={<RelicRewards />} path={RoutesPaths.RelicRewards} />
          {/* <Route element={<Home/>} path="/"/> */}
        </Routes>
      </RootProviders>
    </UIContext.Provider>
  );
};

const Admin: React.FC = () => {
  return <>'Admin Page'</>;
};

export default App;
