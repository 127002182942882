import React, { Component, useEffect, useState } from 'react';
import { getIndividualViewData } from "../actions/individualview.action";
import SingleCard from "./singlecard.component";
import { useLocation } from "react-router-dom";
import NavigationBar from "./navbar/navbar.component";
import Redeemable from "./redeemable.component";

interface CardState {
    wzid: string
}

const ManagerInspect = () => {
    const location = useLocation();
    const data = location.state as CardState;
    const [manager, setManager] = useState<any>(null);
    const [redeemables, setRedeemables] = useState<any>(null);

    useEffect(() => {
        GetIndividualViewData(data.wzid).then(
            (data) => {
                setManager(data);
            }
        );

        GetIndividualRedeemablesData(data.wzid).then(
            (data) => {
                setRedeemables(data);
            }
        );
    }, []);

    async function GetIndividualViewData(wzid: string) {
        var manager: any;

        var managerData = await getIndividualViewData(wzid).call(null);
        manager = <>
            <SingleCard name={managerData.Name} level={managerData.Level} dateAcquired={new Date(managerData.DateCreated).toLocaleDateString()} username={managerData.UserId}></SingleCard>
        </>;
        return manager;
    }

    async function GetIndividualRedeemablesData(wzid: string) {
        var redeemables: any;

        // var redeemablesData = await getIndividualViewData(wzid).call(null);
        // console.log(redeemablesData);
        // for (let c = 0; c < redeemablesData.length; c++) {
        //     redeemables.push(<>
        //         <Redeemable name={redeemablesData[c].Name}></Redeemable>
        //     </>);
        // }

        redeemables = <>
            <Redeemable sourceName="Burger King" redeemableName="Whopper" redeemableInfo="Level 5 / Silver"></Redeemable>
        </>;

        return redeemables;
    }

    return (
        <>
            {/* <NavigationBar setCurrentView={setCurrentView} /> */}
            {manager}
            <div className="redeemable-entry-body">
                {redeemables}
            </div>
        </>
    );
}

export default ManagerInspect;