import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import TowerTycoonCard from "./towertycoon.card.component";
// import ProEraCard from "./proera.card.component";
// import BeatSaberCard from "./beatsaber.card.component";
// import NFTDetails from "./NFTDetails.card.component";
import { useHover } from "../../contexts/ui/ui.context";
import { useUserState } from "../../contexts/user/user.context";
import { getUserInventory } from "../../actions/userinventory.action";
import Redeemables from "./redeemables.card.component";

interface ISlideView {
  title: string;
  setPanelOpen: Function;
  setCollectionTitle: Function;
  setCurrentView: Function;
  setQRCodeOpen: Function;
  setRedeemableInfoOpen: Function;
  setCurrentRedeemable: Function;
}

const SlideView = (props: ISlideView) => {
  const [managers, setManagers] = useState<any>(null);
  const { hover } = useHover();
  const { user } = useUserState();

  async function getWazuzuCards(userId: string) {
    var managerData = await getUserInventory(userId).call(null);
    return managerData.map((m: any) => (
      <div className="slide-flex-container">
        <TowerTycoonCard
          brand={"Tower Tycoons"}
          isNFT={false}
          rarity={m.Rarity}
          title={m.Name}
          level={m.Level}
          key={m.WZId}
        ></TowerTycoonCard>
      </div>
    ));
  }

  useEffect(() => {
    if (
      managers == null &&
      props.title === "Tower Tycoons" &&
      user !== undefined &&
      user !== null
    ) {
      getWazuzuCards(user?.username!).then((data) => {
        setManagers(data);
      });
    }
  }, [managers, props.title, user]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1230,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const settingsRedeemables = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  switch (props.title) {
    case "NFTs":
      return (
        <>
          <div
            className={
              hover ? "hovered-slideview slideview mt-4" : "slideview mt-4"
            }
          >
            <h2 className="slideview-title"> {props.title} </h2>
            <Slider {...settings}>
              <div
                className={
                  hover ? "slide-flex-container hover" : "slide-flex-container"
                }
              >
                <TowerTycoonCard
                  brand={"Tower Tycoons"}
                  title="Industrious Joe"
                  level={105}
                  isNFT={true}
                  rarity={"Relic"}
                  setCurrentView={props.setCurrentView}
                  key={"NFT"}
                />
              </div>
            </Slider>
          </div>
          <div className={hover ? "nft-dark-background" : ""} />
        </>
      );
    case "Tower Tycoons":
      return (
        <>
          <div className="slideview mt-4">
            <div className="slideview-flex-row">
              <h2 className="slideview-title"> {props.title} </h2>
              <div
                className="explore-all-text-container"
                onClick={() => {
                  document.body.style.overflowY = "hidden";
                  props.setPanelOpen(true);
                  props.setCollectionTitle("Tower Tycoons");
                }}
              >
                <span className="explore-all-text">
                  Explore All Tower Tycoons
                </span>
                <img className="explore-all-uparrow" alt="arrow-img" />
              </div>
            </div>
            <Slider {...settings}>{managers}</Slider>
          </div>
        </>
      );
    // case "Pro Era":
    //   return (
    //     <>
    //       <div className="slideview mt-4">
    //         <div className="slideview-flex-row">
    //           <h2 className="slideview-title"> {props.title} </h2>
    //           <div
    //             className="explore-all-text-container"
    //             onClick={() => {
    //               document.body.style.overflowY = "hidden";
    //               props.setPanelOpen(true);
    //               props.setCollectionTitle("Pro Era");
    //             }}
    //           >
    //             <span className="explore-all-text">Explore All Pro Era</span>
    //             <img className="explore-all-uparrow" alt="arrow-img" />
    //           </div>
    //         </div>
    //         <Slider {...settings}>
    //           <div className="slide-flex-container">
    //             <ProEraCard
    //               brand={"Pro Era"}
    //               title="Carbon Grip"
    //               level={4}
    //               rarity={"Epic"}
    //             />
    //           </div>
    //           <div className="slide-flex-container">
    //             <ProEraCard
    //               brand={"Pro Era"}
    //               title="Steelers Helmet"
    //               level={15}
    //               rarity={"Common"}
    //             />
    //           </div>
    //           <div className="slide-flex-container">
    //             <ProEraCard
    //               brand={"Pro Era"}
    //               title="Ravens Stadium"
    //               level={115}
    //               rarity={"Legendary"}
    //             />
    //           </div>
    //         </Slider>
    //       </div>
    //     </>
    //   );
    //TODO Create own component for this
    case "Redeemables":
      return (
        <>
          <div className="redeemables-slideview">
            <Slider {...settingsRedeemables}>
              <div className="slide-flex-container">
                <Redeemables
                  brand={"FreshPet"}
                  title={"Freshpet Feast"}
                  setQRCodeOpen={props.setQRCodeOpen}
                  setRedeemableInfoOpen={props.setRedeemableInfoOpen}
                  setCurrentRedeemable={props.setCurrentRedeemable}
                  key={"1"}
                />
              </div>
              {/* <div className="slide-flex-container">
                <Redeemables
                  brand={"FreshPet"}
                  title={"Freshpet Feast"}
                  setQRCodeOpen={props.setQRCodeOpen}
                  setRedeemableInfoOpen={props.setRedeemableInfoOpen}
                  setCurrentRedeemable={props.setCurrentRedeemable}
                />
              </div>
              <div className="slide-flex-container">
                <Redeemables
                  brand={"LA Fitness"}
                  title={"Day Pass"}
                  setQRCodeOpen={props.setQRCodeOpen}
                  setRedeemableInfoOpen={props.setRedeemableInfoOpen}
                  setCurrentRedeemable={props.setCurrentRedeemable}
                />
              </div> */}
              {/* <div className="slide-flex-container">
                <Redeemables
                  brand={"FreshPet"}
                  title={"Freshpet Feast"}
                  setQRCodeOpen={props.setQRCodeOpen}
                  setRedeemableInfoOpen={props.setRedeemableInfoOpen}
                  setCurrentRedeemable={props.setCurrentRedeemable}
                />
              </div>
              <div className="slide-flex-container">
                <Redeemables
                  brand={"LA Fitness"}
                  title={"Day Pass"}
                  setQRCodeOpen={props.setQRCodeOpen}
                  setRedeemableInfoOpen={props.setRedeemableInfoOpen}
                  setCurrentRedeemable={props.setCurrentRedeemable}
                />
              </div>
              <div className="slide-flex-container">
                <Redeemables
                  brand={"FreshPet"}
                  title={"Freshpet Feast"}
                  setQRCodeOpen={props.setQRCodeOpen}
                  setRedeemableInfoOpen={props.setRedeemableInfoOpen}
                  setCurrentRedeemable={props.setCurrentRedeemable}
                /> 
              </div>*/}
            </Slider>
          </div>
        </>
      );
    default:
      return null;
  }
};

export default SlideView;
