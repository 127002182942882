import axios from "axios";
const axiosInst = axios.create({ baseURL: "http://vaultservices.wazuzu.com" });
export const getStoredRelicRewards = () => async () => {
  try {
    const res = await axiosInst.post("/api/wvuserrewards/relic");
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
