import React, { useEffect } from "react";
import RelicRewardsContent from "../components/relicrewards/relicrewardscontent.component";

interface IRelicRewards {}

const RelicRewards = (props: IRelicRewards) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="markercreator-body">
        <RelicRewardsContent />
      </div>
    </>
  );
};

export default RelicRewards;
