import axios from 'axios'
const axiosInst = axios.create({
  baseURL: 'http://vaultservices.wazuzu.com',
  headers: {
    'Content-Type': 'application/json',
  },
})
export const postMarker = (
  lat: string,
  lon: string,
  markerName: string,
) => async () => {
  try {
    const res = await axiosInst.post('/api/wvmarkers', {
      Lat: lat,
      Lon: lon,
      MarkerName: markerName,
    })
    return res.status
  } catch (err) {
    console.log(err)
    return 500
  }
}
