import React, { useEffect } from "react";
import SlideView from "../slider/slideview.component";
// import NFTTimelineEntry from "./nfttimelineentry.component";

interface INFTOverview {
  setQRCodeOpen: Function;
  setRedeemableInfoOpen: Function;
  setCurrentRedeemable: Function;
}

const NFTOverview = (props: INFTOverview) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="nft-overview-body">
        {/* <div className='nft-overview-redeemables-number-container'>
                    <span className='nft-overview-redeemables-number-text'>Redeemables: 7</span>
                </div> */}

        {/* Slide View */}
        <SlideView
          title="Redeemables"
          setPanelOpen={() => void 0}
          setCollectionTitle={() => void 0}
          setCurrentView={() => void 0}
          setQRCodeOpen={props.setQRCodeOpen}
          setRedeemableInfoOpen={props.setRedeemableInfoOpen}
          setCurrentRedeemable={props.setCurrentRedeemable}
        />

        {/* Description Text */}
        <div className="description-container">
          <span className="description-header">Description</span>
          <span className="description-text">
            Industrious Joe, the legendary tower defense boss, has a history as
            rugged as his appearance. Born in the heart of the factories, he
            grew up amongst the gears and cogs, learning the ins and outs of
            industrial machinery from a young age.As he rose through the ranks,
            his reputation as the ultimate tower defense weapon spread far and
            wide. With this exclusive exotic version of Industrious Joe, players
            have the chance to delve deeper into the backstory of this
            formidable boss. With unique abilities and a past shrouded in
            mystery, this asset is not just a powerful tool in the game, but a
            true piece of tower defense history.
          </span>
        </div>

        {/* Category Info */}
        <div className="category-container">
          <span className="category-header">Category:</span>
          <div className="category-entry-container">
            <span className="category-entry-text">Mobile Gaming</span>
          </div>
          <div className="category-entry-container">
            <span className="category-entry-text">Tower Defence</span>
          </div>
          <div className="category-entry-container">
            <span className="category-entry-text">Strategy</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTOverview;
