import React, { Component, useEffect, useState } from 'react';
import MarkerCreatorContent from '../components/markercreator/markercreatorcontent.component';

interface IMarkerCreator {

}

const MarkerCreator = (props: IMarkerCreator) => {

    useEffect(() => {
    }, []);

    return (
        <>
            <div className="markercreator-body">
                <MarkerCreatorContent />
            </div>
        </>
    );
}

export default MarkerCreator;