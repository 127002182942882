import axios from 'axios'

const axiosInst = axios.create({
  baseURL: 'http://vaultservices.wazuzu.com',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const createUser = async (
  username: string,
  encryptedKey: string,
  publicKey: string,
  accountId: string,
) => {
  try {
    const res = await axiosInst.post('/api/wvusers', {
      Username: username,
      HederaAccountId: accountId,
      HederaEncryptedPrivateKey: encryptedKey,
      HederaPublicKey: publicKey,
    })
    return res
  } catch (error) {
    return (error as Error).toString()
  }
}
