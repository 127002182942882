import React, { useEffect } from "react";
import TowerTycoonsIcon from "../../media/towertycoons-icon.svg";
import { useHover } from "../../contexts/ui/ui.context";

interface INFTDetails {
  brand: string;
  title: string;
  level: number;
  setDisplay: Function;
  rarity: string;
  setCurrentView: Function;
}

const NFTDetails = (props: INFTDetails) => {
  const { setHover } = useHover();

  useEffect(() => {}, []);

  //dynamically add classes by name, will be replaced later by loading from url
  let classesCardBackground = [
    "nft-card-background",
    props.rarity.toLowerCase(),
  ].join(" ");

  switch (props.brand) {
    case "Tower Tycoons":
      return (
        <>
          <div
            className="nft-main-container"
            onMouseLeave={() => {
              setHover(false);
              props.setDisplay(false);
            }}
            onClick={() => props.setCurrentView("NFTInformation")}
          >
            <div className={classesCardBackground}>
              <img
                src={TowerTycoonsIcon}
                className="brand-icon"
                alt="brand-icon"
              />
              <img className="nft-card-manager-image" alt="nft-img" />
              <div className="tt-card-managername-container">
                <span className="tt-card-managername-text">{props.title}</span>
              </div>
              <div className="card-levelnumber-container">
                <div className="card-levelnumber-flex-container">
                  <span className="card-levelnumber-text"> {props.level} </span>
                </div>
              </div>
            </div>
            <div className="nft-details-container">
              <div className="nft-details-row">
                <span className="os-bold nft-title-text">{props.brand}</span>
                <span className="os-bold right-align nft-detail-type">
                  Ranking:{" "}
                </span>
                <span>&nbsp;{props.rarity}</span>
              </div>
              <div className="nft-details-row">
                {/* <span className="os-bold">Trade Freq: </span>
                <span>&nbsp;32</span> */}
                <span>Mobile Game - Tower Defense</span>
                <span className="os-bold right-align">Type: </span>
                <span>&nbsp;NFT</span>
              </div>
              <div className="nft-details-row">
                {/* <span>Mobile Game - Tower Defense</span> */}
                {/* <span className='right-align'>Open Sea</span>
                                <img className="nft-opensea-icon" /> */}
              </div>
            </div>
          </div>
        </>
      );
    // case "Beat Saber":
    //     return (
    //         <>
    //             <div className="pe-card-background">
    //                 <img src={ProEraIcon} className="brand-icon" />
    //                 <img className="pe-card-manager-image" />
    //                 <div className="pe-card-managername-container">
    //                     <img className="pe-logo" />
    //                     <span className="pe-card-managername-text">Test Name</span>
    //                 </div>
    //                 <div className="card-levelnumber-container">
    //                     <div className='card-levelnumber-flex-container'>
    //                         <span className="card-levelnumber-text"> 102 </span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     );
    // case "Pro Era":
    //     return (
    //         <>
    //             <div className="pe-card-background">
    //                 <img src={ProEraIcon} className="brand-icon" />
    //                 <img className="pe-card-manager-image" />
    //                 <div className="pe-card-managername-container">
    //                     <img className="pe-logo" />
    //                     <span className="pe-card-managername-text">Test Name</span>
    //                 </div>
    //                 <div className="card-levelnumber-container">
    //                     <div className='card-levelnumber-flex-container'>
    //                         <span className="card-levelnumber-text"> 102 </span>
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     );
    default:
      return null;
  }
};

export default NFTDetails;
