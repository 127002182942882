import React, { useEffect } from "react";
import TowerTycoonsIcon from "../../media/towertycoons-icon.svg";
// import ShareIcon from "../../media/Share-Icon.svg";
// import Watchlist from "../../media/Watchlist-Icon.svg";

interface ICardDetails {
  setCurrentView: Function;
}

const CardDetails = (props: ICardDetails) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="card-details-body">
        <div className="card-image">
          <div className="card-name-container">
            <span className="card-name">Industrious Joe</span>
          </div>
        </div>
        <div className="card-details-container">
          <div className="card-details-flex-row">
            <span className="nft-name-text">Industrial Joe #32</span>
            <div className="nft-rarity-container">
              <span className="nft-rarity-text">Relic</span>
            </div>
            {/* <div className="nft-level-img-container">
              <div className="nft-level-img-flex-container">
                <span className="nft-level-text">105</span>
              </div>
            </div> */}
            <img className="nft-market-img" alt="nft-market-img" />
          </div>
          <div className="card-details-flex-row">
            <div className="nft-source-panel">
              <img
                src={TowerTycoonsIcon}
                className="nft-source-img"
                alt="source-img"
              />
              <span className="nft-data-text">Tower Tycoons</span>
            </div>
            <div className="nft-data-column ml-auto">
              <span className="nft-data-header ml-auto">Price</span>
              <span className="nft-data-text">0.012 ETH</span>
            </div>
            <div className="nft-data-column ml-auto">
              <span className="nft-data-header ml-auto">Owner Address</span>
              <span className="nft-data-text">0xcd11770a3...7787</span>
            </div>
          </div>
          <div className="card-details-flex-row">
            <div className="nft-owner-creator-panel">
              <div className="nft-data-column ownercreator-column">
                <span className="nft-data-header mr-auto header-margin">
                  Owner
                </span>
                <div className="data-flex-row mr-auto">
                  <img className="nft-ownercreator-img" alt="nft-owner-img" />
                  <span className="nft-data-text">Wazuzu</span>
                </div>
              </div>
              <div className="nft-data-column ownercreator-column">
                <span className="nft-data-header mr-auto header-margin">
                  Creator
                </span>
                <div className="data-flex-row mr-auto">
                  <img className="nft-ownercreator-img" alt="nft-creator-img" />
                  <span className="nft-data-text">Wazuzu</span>
                </div>
              </div>
              <div className="nft-data-column ownercreator-column">
                {/* <span className="nft-data-header mr-auto header-margin">
                  Creator Address
                </span>
                <div className="data-flex-row mr-auto">
                  <span className="nft-data-text">0xcd11770a3...7787</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className="card-details-flex-row">
            <div
              className="view-select-container"
              onClick={() => props.setCurrentView("Overview")}
            >
              <span className="view-select-text">Overview</span>
            </div>
            <div
              className="view-select-container view-select-margin"
              onClick={() => props.setCurrentView("History")}
            >
              <span className="view-select-text">History</span>
            </div>
            <div className="view-select-container view-select-margin view-select-active">
              <span className="view-select-text">Own It</span>
            </div>
            {/* <img
              src={Watchlist}
              className="watchlistImg ml-auto"
              alt="watchlist-icon"
            />
            <span className="watchlistshare-text">Watchlist</span>
            <img src={ShareIcon} className="shareImg" alt="share-icon" />
            <span className="watchlistshare-text">Share</span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetails;
