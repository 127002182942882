import React, { Component, useEffect, useState } from 'react';
import CardDetails from '../components/nftinformation/carddetails.component';
import NFTOverview from '../components/nftinformation/nftoverview.component';
import NFTHistory from '../components/nftinformation/nfthistory.component';
import NFTPopularCollection from '../components/nftinformation/nftpopularcollection.component';
import QRCodePanel from '../components/nftinformation/qrcodepanel.component';
import RedeemableInfoPanel from '../components/nftinformation/reedemableinfopanel.component';
import { Redeemable } from '../types/types';

interface INFTInformation {

}

const NFTInformation = (props: INFTInformation) => {
    const [currentView, setCurrentView] = useState("");
    const [qrCodeOpen, setQRCodeOpen] = useState(false);
    const [redeemableInfoOpen, setRedeemableInfoOpen] = useState(false);
    const [currentRedeemable, setCurrentRedeemable] = useState<Redeemable>();

    useEffect(() => {
    }, []);

    switch (currentView) {
        case "Overview":
            return (
                <>
                    <div className="nft-information-body">
                        {/* Card Details */}
                        <CardDetails setCurrentView={setCurrentView} />

                        {/* Overview Slider */}
                        <NFTOverview setQRCodeOpen={setQRCodeOpen} setRedeemableInfoOpen={setRedeemableInfoOpen} setCurrentRedeemable={setCurrentRedeemable} />

                        {/* Popular Collection */}
                        <NFTPopularCollection />

                        {qrCodeOpen && (
                            <QRCodePanel setQRCodeOpen={setQRCodeOpen} currentRedeemable={currentRedeemable!} />
                        )}

                        {redeemableInfoOpen && (
                            <RedeemableInfoPanel setQRCodeOpen={setQRCodeOpen} setRedeemableInfoOpen={setRedeemableInfoOpen} currentRedeemable={currentRedeemable!}  />
                        )}
                    </div>
                </>
            );
        case "History":
            return (
                <>
                    <div className="nft-information-body">
                        {/* Card Details */}
                        <CardDetails setCurrentView={setCurrentView} />

                        {/* History Scroll View */}
                        <NFTHistory />

                        {/* Popular Collection */}
                        <NFTPopularCollection />
                    </div>
                </>
            );
        default:
            return (
                <>
                    <div className="nft-information-body">
                        {/* Card Details */}
                        <CardDetails setCurrentView={setCurrentView} />

                        {/* Overview Slider */}
                        <NFTOverview setQRCodeOpen={setQRCodeOpen} setRedeemableInfoOpen={setRedeemableInfoOpen} setCurrentRedeemable={setCurrentRedeemable} />

                        {/* Popular Collection */}
                        <NFTPopularCollection />

                        {qrCodeOpen && (
                            <QRCodePanel setQRCodeOpen={setQRCodeOpen} currentRedeemable={currentRedeemable!} />
                        )}

                        {redeemableInfoOpen && (
                            <RedeemableInfoPanel setQRCodeOpen={setQRCodeOpen} setRedeemableInfoOpen={setRedeemableInfoOpen} currentRedeemable={currentRedeemable!} />
                        )}
                    </div>
                </>
            );
    }
}

export default NFTInformation;