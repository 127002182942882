import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { postMarker } from "../../actions/markers.action";
import { getStoredRelicRewards } from "../../actions/relicrewards.action";

const RelicRewardsContent = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getRelicRewards();
  }, []);

  const getRelicRewards = async () => {
    //request to action
    let rewards = await getStoredRelicRewards().call(null);
    if (rewards.length === 0) {
      setAlertMessage("No Relics Rewarded");
    } else {
      setAlertMessage("");
    }
    for (var entry of rewards) {
      const date = new Date(entry.DateRewarded);
      var date_String =
        date.getMonth() +
        1 +
        "/" +
        +date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        +date.getHours() +
        ":" +
        +date.getMinutes();
      entry.DateRewarded = date_String;
    }
    setData(rewards);
  };

  return (
    <>
      <img
        src={require("../../media/logo_wazuzudungeon.png")}
        className="markercreatorcontent-dungeonlogo"
        alt="Wazuzu Logo"
        onClick={() => navigate("/")}
      />
      <h1 className="markercreatorcontent-title">Relic Rewards</h1>
      {/* <span className="markercreatorcontent-instructions">
        All Relic User Rewards
      </span> */}

      <br />
      <br />
      <br />

      <table className="table table-striped text-white table-bordered text-center w-50">
        <thead>
          <tr>
            <th>User Id</th>
            <th>Email</th>
            <th>Date/Time Rewarded</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any) => (
            <tr key={item}>
              <td>{item.UserId}</td>
              <td>{item.Email}</td>
              <td>{item.DateRewarded}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <span className="markercreatorcontent-response">{alertMessage}</span>
    </>
  );
};

export default RelicRewardsContent;
